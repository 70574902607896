<!-- INPUT TYPE: SINGLE -->
<ng-container *ngIf="customFieldGroup && fieldConfig.inputType !== 'multiple'"
              [ngSwitch]="customFieldGroup.type">

  <!-- EXPANSION PANEL -->
  <ng-container *ngSwitchCase="FieldGroupType.ExpansionPanel">
    <mat-expansion-panel expanded
                         class="gd-fgb__expansion-panel"
                         [style.width.%]="width">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="gd-fgb__group-title">{{ fieldConfig.label }}:</span>
          <span class="gd-fgb__group-subtitle">{{ customFieldGroup.name }}</span>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon *ngIf="!!fieldConfig?.description || readOnlyMode"
                    [matTooltip]="infoTooltip"
                    fontSet="fal"
                    fontIcon="fa-question-circle">
          </mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <ng-container *ngFor="let field of flatFieldDefinitions; let i = index;">
          <gd-field-builder *ngIf="!field.hidden"
                            fxFlex="100%"
                            [width]="100"
                            [fieldConfig]="field"
                            [isEditingMode]="isEditingMode"
                            [fieldControl]="typecastAbstractToFormControl(fieldControlGroup?.get(field.key))"
                            [hasActionPermission]="hasActionPermission"
                            [usage]="usage"
                            [activeLocale]="activeLocale"
                            [readOnlyMode]="readOnlyMode || field.readOnly">
          </gd-field-builder>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </ng-container>

  <!-- SIMPLE GROUP | ACCORDION | STEPPER -->
  <ng-container *ngSwitchDefault>
    <mat-card class="gd-fgb__group"
              [style.width.%]="width">
      <div class="gd-fgb__group-header">
        <div>
          <span class="gd-fgb__group-title">{{ fieldConfig.label }}:</span>
          <span class="gd-fgb__group-subtitle">{{ customFieldGroup.name }}</span>
        </div>
        <mat-icon *ngIf="!!fieldConfig?.description || readOnlyMode"
                  [matTooltip]="infoTooltip"
                  fontSet="fal"
                  fontIcon="fa-question-circle">
        </mat-icon>
      </div>

      <mat-card-content [formGroup]="fieldControlGroup"
                        [class.--accordion]="customFieldGroup.type === FieldGroupType.Accordion">
        <!-- SIMPLE GROUP -->
        <ng-container *ngIf="customFieldGroup.type === FieldGroupType.SimpleGroup">
          <div fxLayout="column">
            <ng-container *ngFor="let field of flatFieldDefinitions; let i = index;">
              <gd-field-builder *ngIf="!field.hidden"
                                fxFlex="100%"
                                [width]="100"
                                [fieldConfig]="field"
                                [fieldControl]="typecastAbstractToFormControl(fieldControlGroup?.get(field.key))"
                                [hasActionPermission]="hasActionPermission"
                                [usage]="usage"
                                [activeLocale]="activeLocale"
                                [readOnlyMode]="readOnlyMode || field.readOnly">
              </gd-field-builder>
            </ng-container>
          </div>
        </ng-container>

        <!-- ACCORDION -->
        <ng-container *ngIf="customFieldGroup.type === FieldGroupType.Accordion">
          <mat-accordion displayMode="flat"
                         class="gd-fgb__accordion">
            <mat-expansion-panel *ngFor="let group of customFieldGroup.fieldDefinitions">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ group.label }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="column">
                <ng-container *ngFor="let field of group.fields; let i = index;">
                  <gd-field-builder *ngIf="!field.hidden"
                                    fxFlex="100%"
                                    [width]="100"
                                    [fieldConfig]="field"
                                    [fieldControl]="typecastAbstractToFormControl(fieldControlGroup?.get(field.key))"
                                    [hasActionPermission]="hasActionPermission"
                                    [usage]="usage"
                                    [activeLocale]="activeLocale"
                                    [readOnlyMode]="readOnlyMode || field.readOnly">
                  </gd-field-builder>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>

        <!-- STEPPER -->
        <ng-container *ngIf="customFieldGroup.type === FieldGroupType.Stepper">
          <mat-vertical-stepper #stepper
                                  class="gd-fgb__stepper">
            <mat-step *ngFor="let group of customFieldGroup.fieldDefinitions">
              <div class="gd-fgb__step-content">
                <ng-template matStepLabel>{{ group.label }}</ng-template>
                <div fxLayout="column">
                  <ng-container *ngFor="let field of group.fields; let i = index;">
                    <gd-field-builder *ngIf="!field.hidden"
                                      [width]="100"
                                      [fieldConfig]="field"
                                      [fieldControl]="typecastAbstractToFormControl(fieldControlGroup?.get(field.key))"
                                      [hasActionPermission]="hasActionPermission"
                                      [usage]="usage"
                                      [activeLocale]="activeLocale"
                                      [readOnlyMode]="readOnlyMode || field.readOnly">
                    </gd-field-builder>
                  </ng-container>
                </div>
              </div>
            </mat-step>
          </mat-vertical-stepper>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>

<!-- INPUT TYPE: MULTIPLE -->
<ng-container *ngIf="customFieldGroup && fieldConfig.inputType === 'multiple'">

  <mat-card class="gd-fgb__group --multiple"
            [style.width.%]="width">
    <div class="gd-fgb__group-header">
      <div>
        <span class="gd-fgb__group-title">{{ fieldConfig.label }}</span>
      </div>
      <mat-icon *ngIf="!!fieldConfig?.description || readOnlyMode"
                [matTooltip]="infoTooltip"
                fontSet="fal"
                fontIcon="fa-question-circle">
      </mat-icon>
    </div>

    <mat-card-content [formGroup]="fieldControlGroup">
      <ng-container *ngIf="customFieldGroup.type === FieldGroupType.SimpleGroup">
        <button *ngIf="!readOnlyMode"
                mat-button
                mat-raised-button
                color="accent"
                (click)="addFormGroupToArray($event)"
                style="margin-bottom: 15px;">
          <mat-icon fontSet="fal"
                    fontIcon="fa-plus"></mat-icon>
          <span i18n>Add new</span>
        </button>
        <div cdkDropList
             (cdkDropListDropped)="reorderFormGroup($event)"
             [cdkDropListDisabled]="readOnlyMode">
          <mat-expansion-panel *ngFor="let group of formArray.controls; let i = index; let last = last;"
                               cdkDrag
                               [cdkDragDisabled]="formArray.controls.length < 2"
                               cdkDragLockAxis="y"
                               #fgb_form_fragment
                               class="gd-fgb__form-fragment"
                               (opened)="handlePanelExpansion(fgb_form_fragment.id)">
            <mat-expansion-panel-header cdkDragHandle>
              <mat-panel-title class="gd-fgb__group-title">
                <span>{{ i + 1 }}.</span>
                <!-- here we'd use the CFG config to figure out which field value to use instead of just going for the first one -->
                <span *ngIf="hasCustomLabel()" class="gd-fgb__group-label">
                  <gd-ellipsis-label-tooltip
                  [availableSpace]="availableLabelSpace"
                  usage="field-group"
                  [tooltipLabel]="getCustomLabel(group, i)">
                  {{getCustomLabel(group, i) }}
                </gd-ellipsis-label-tooltip>
               </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
              <mat-icon *ngIf="!readOnlyMode"
                        class="gd-cfg__icon-del"
                        (click)="removeFormGroupFromArray(i)">delete</mat-icon>
              <div style="margin-top: 10px;"
                   fxLayout="column"
                   [style.min-height.px]="flatFieldDefinitions.length * 60">
                <ng-container *ngFor="let field of flatFieldDefinitions">
                  <gd-field-builder *ngIf="!field.hidden && ((isPanelLoaded(fgb_form_fragment.id) || field?.key === formFragmentTitleKey) || isExternalDataSource)"
                                    fxFlex="100%"
                                    [width]="90"
                                    [fieldConfig]="field"
                                    [isEditingMode]="isEditingMode"
                                    [fieldControl]="typecastAbstractToFormControl(group?.get(field.key))"
                                    [hasActionPermission]="hasActionPermission"
                                    [usage]="usage"
                                    [activeLocale]="activeLocale"
                                    [readOnlyMode]="readOnlyMode || field.readOnly">
                  </gd-field-builder>
                </ng-container>
              </div>
            <div class="gd-fgb__group-placeholder"
                 *cdkDragPlaceholder></div>
          </mat-expansion-panel>
        </div>
        <div class="gd-fgb__dnd-hint"
             *ngIf="formArray.controls.length > 1">
          <mat-icon fontSet="fas"
                    fontIcon="fa-arrows">
          </mat-icon>
          <div *ngIf="!readOnlyMode"
               fxFlex="calc(100% - 30px)"
               class="gd-fgb__dnd-hint-text"
               i18n>
            Drag and drop to reorder field groups.
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="customFieldGroup.type !== FieldGroupType.SimpleGroup">
        <p i18n>Unsupported custom field group for the input type <b>multiple</b>.</p>
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-container>


<!-- Displayed during the loading of the CFG configuration -->
<mat-card *ngIf="!customFieldGroup && !loadingError"
          [style.width.%]="width"
          class="gd-fgb__group">

  <div class="gd-fgb__group-header">
    <span class="gd-fgb__group-title" fxLayout="row" fxLayoutGap="12px">
      <span>{{ fieldConfig.label }}: </span>
      <mat-spinner diameter="20"></mat-spinner>
    </span>
    <mat-icon *ngIf="!!fieldConfig?.description || readOnlyMode"
              [matTooltip]="infoTooltip"
              fontSet="fal"
              fontIcon="fa-question-circle">
    </mat-icon>
  </div>
  <mat-card-content i18n>
    Loading configuration...
  </mat-card-content>
  <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
</mat-card>


<!-- This will display if the CFG has been deleted or otherwise cannot be fetched -->
<mat-card *ngIf="!customFieldGroup && loadingError"
          [style.width.%]="width"
          class="gd-fgb__group gd-fgb__loading-error">
  <div class="gd-fgb__group-header">
    <span class="gd-fgb__group-title" fxLayout="row" fxLayoutGap="4px">
      <mat-icon>warning</mat-icon>
      <span>{{ fieldConfig.label }}</span>
    </span>
    <mat-icon *ngIf="!!fieldConfig?.description || readOnlyMode"
              [matTooltip]="infoTooltip"
              fontSet="fal"
              fontIcon="fa-question-circle">
    </mat-icon>
  </div>
  <mat-card-content i18n>
    Configuration loading error!
  </mat-card-content>
</mat-card>
