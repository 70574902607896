import { createSelector } from '@ngrx/store';
import { AppState } from '../app-reducer';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  CLEAR_ARTICLE_QUICK_VIEW,
  GET_ARTICLE_QUICK_VIEW,
  GET_ARTICLE_QUICK_VIEW_SUCCESS,
  MANAGE_EMBEDDED_IMAGE_VISIBILITY,
  UPDATE_REFERENCED_CONTENT,
} from './article-quick-view.actions';
import { ArticleQuickViewState, getArticleQuickViewInitialState } from './article-quick-view.model';

const initialState: ArticleQuickViewState = getArticleQuickViewInitialState();

export function articleQuickViewReducer(
  state: ArticleQuickViewState = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case GET_ARTICLE_QUICK_VIEW: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ARTICLE_QUICK_VIEW_SUCCESS: {
      const article = action.payload;
      const { id, latestRevision, publishedRevision } = article;
      return {
        ...state,
        article: {
          ...article
        },
        loading: false,
      };
    }
    case CLEAR_ARTICLE_QUICK_VIEW: {
      return getArticleQuickViewInitialState();
    }

    case MANAGE_EMBEDDED_IMAGE_VISIBILITY: {
      const data = action.payload;
      const affectedNode = state.referencedContent[data.id];
      if (!affectedNode || affectedNode.dataId !== data.dataId) {
        // stop executing image checker
        clearTimeout(data.timeout);
        return state;
      }
      return {
        ...state,
        referencedContent: {
          ...state.referencedContent,
          [data.id]: { ...affectedNode, src: data.src || affectedNode.src, isNotLoaded: data.isNotLoaded }
        }
      };
    }

    case UPDATE_REFERENCED_CONTENT: {
      return {
        ...state,
        referencedContent: {
          ...state.referencedContent,
          ...action.payload
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const getArticleQuickViewState = (state: AppState): ArticleQuickViewState => state.articleQuickView;
export const getActiveArticle = createSelector(getArticleQuickViewState, (state) => state.article);
export const getReferencedContent = createSelector(getArticleQuickViewState, (state) => state.referencedContent);
export const getArticleQuickViewLoading = createSelector(getArticleQuickViewState, (state) => state.loading);
