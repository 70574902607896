<div *ngIf="displayInfo$ | async as sectionInfo" [dir]="dir$ | async">
  <popper-content #infoContent>
    <span class="gd-section-info__label">{{label}}</span>
    <section fxLayout="column"
             style="margin-top: 24px"
             fxLayoutGap="16px">
      <div *ngIf="sectionInfo.updatedAt && sectionInfo.updatedAt !== sectionInfo.createdAt"
           fxLayout="row"
           fxLayoutAlign="start center"
           fxLayoutGap="12px">
        <img [attr.src]="sectionInfo.updatedBy.userImage"
             class="gd-section-info__user-image">
        <div fxLayout="column">
          <span>Updated by: </span>
          <span fxLayout="row"
                fxLayoutGap="8px">
            <span class="gd-section-info__user-name"
                  #updatedByName
                  #tooltip="matTooltip"
                  [matTooltip]="sectionInfo.updatedBy.name"
                  [matTooltipDisabled]="doesNameFitContainer(updatedByName)">
              <b>{{sectionInfo.updatedBy.name}}</b>
            </span>
            <span>|</span>
            <span style="font-size: 12px;" dir="ltr">
              {{sectionInfo.updatedAt | gdDateTransform}}
            </span>
          </span>
        </div>
      </div>
      <div fxLayout="row"
           fxLayoutAlign="start center"
           fxLayoutGap="12px">
        <img [attr.src]="sectionInfo.createdBy.userImage"
             class="gd-section-info__user-image">
        <div fxLayout="column">
          <span>Created by: </span>
          <span fxLayout="row"
                fxLayoutGap="8px">
            <span class="gd-section-info__user-name"
                  #createdByName
                  #tooltip="matTooltip"
                  [matTooltip]="sectionInfo.createdBy.name"
                  [matTooltipDisabled]="doesNameFitContainer(createdByName)">
              <b>{{sectionInfo.createdBy.name}}</b>
            </span>
            <span>|</span>
            <span style="font-size: 12px;" dir="ltr">
              {{sectionInfo.createdAt | gdDateTransform}}
            </span>
          </span>
        </div>
      </div>
    </section>
  </popper-content>

  <mat-icon fontSet="fal"
            class="gd-section-info__icon"
            [popper]="infoContent"
            fontIcon="fa-info-circle"
            [popperModifiers]="modifiers"
            [popperTrigger]="popperTrigger"
            [popperApplyClass]="'gd-section-info__popper'"
            [popperApplyArrowClass]="'gd-section-info__popper-arrow'"
            [popperHideOnClickOutside]="true"
            [popperHideOnScroll]="true"
            [popperPlacement]="popperPlacement">
  </mat-icon>
</div>
