import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, public router: Router) {}

  handleError(error: any) {
    const errorMessage = error?.message || '';

    const isRouteMismatchError = !!errorMessage.match(/cannot match any routes/i);
    if (isRouteMismatchError) {
      this.zone.run(() => {
        console.warn('Cannot match any routes! Redirecting to dashboard');
        this.router.navigate(['/dashboard']);
      })
      return;
    }

    const isNoPreviousAuthData = !!errorMessage.match(/no user data, cannot restore user session/i)
    if(isNoPreviousAuthData) {
      console.info("No previous user auth state data, going to login screen.")
      return;
    }

    console.error(error)

  }
}
