<div class="gd-media-widget-configuration" *ngIf="!fieldConfig.inputType || (fieldConfig.inputType === 'single' && mediaType === mediaTypes.Files)">
  <div style="display: flex; flex-direction: row; align-items: center; gap: 6px;">
  <label class="gd-media-widget-configuration__label"
         [class.--error]="fieldControl.hasError('required') && fieldControl.touched">{{fieldConfig.label}}{{isFormControlRequired ? ' *' : ''}}</label>
         <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
                  class="question-icon"
                  [matTooltip]="infoTooltip"
                  matTooltipPosition="above" matSuffix fontSet="fal"
                  fontIcon="fa-question-circle"
                  style="color: #8e8e8e"></mat-icon></div>
  <div class="gd-media-widget-configuration__file-reference" *ngIf="mediaType === mediaTypes.Files && selectedFile" [class.--read-only]="readOnlyMode">
    <label>Filename: <span>{{ selectedFile?.filename }}</span></label>
    <label *ngIf="selectedFile['meta_data']?.name">Name: <span>{{ selectedFile['meta_data']?.name }}</span></label>
  </div>
  <div class="gd-media-widget-configuration__image-wrapper">
    <div class="gd-media-widget-configuration__image"
         [class.--read-only]="readOnlyMode">
      <!-- Image and gallery reference content -->
      <img class="gd-media-widget-configuration__img"
           *ngIf="previewImageUrl && !isIconPreview && fieldControl.value"
           [src]="previewImageUrl"
           alt=""
           [class.--gallery-unpublished]="mediaType === mediaTypes.Galleries && !isGalleryPublished"
           [class.--read-only]="readOnlyMode">
      <!-- File reference content -->
      <mat-icon class="gd-media-widget-configuration__icon"
                *ngIf="isIconPreview && isCustomSvgIcon"
                svgIcon="{{ previewImageUrl }}"></mat-icon>
      <mat-icon class="gd-media-widget-configuration__icon"
                *ngIf="isIconPreview && !isCustomSvgIcon"
                fontSet="fal"
                fontIcon="{{ previewImageUrl }}"></mat-icon>
      <!-- Image/File deleted reference content  -->
      <div class="gd-media-widget-configuration__deleted-reference"
                 [class.--read-only]="readOnlyMode"
           *ngIf="mediaType === mediaTypes.Images || (mediaType === mediaTypes.Files && (!previewImageUrl || isReferenceItemDeleted))">
        <!-- Image placeholder -->
        <mat-icon class="gd-media-widget-configuration__placeholder-icon"
                  [class.--image]="mediaType === mediaTypes.Images"
                  *ngIf="mediaType === mediaTypes.Images && !previewImageUrl && fieldControl.value"
                  fontSet="fal"
                  fontIcon="fa-image"></mat-icon>
        <img *ngIf="mediaType === mediaTypes.Images && !fieldControl.value"
             src="./../../../../assets/img/gpp-small.svg"
             alt="Image placeholder"
             style="margin:auto"
             height="42"
             width="42">
        <!-- File placeholder -->
        <mat-icon class="gd-media-widget-configuration__placeholder-icon"
                  [class.--file]="mediaType === mediaTypes.Files"
                  [style.padding-top]="mediaType === mediaTypes.Files && !fieldControl.value && '38px'"
                  *ngIf="mediaType === mediaTypes.Files && (!previewImageUrl || isReferenceItemDeleted)"
                  fontSet="fal"
                  fontIcon="fa-copy"></mat-icon>
        <span *ngIf="!previewImageUrl && fieldControl.value"
              [matTooltip]="fieldControl?.value"
              class="gd-media-widget-configuration__deleted-reference-message">
          [{{mediaType === mediaTypes.Files ? 'File' : 'Image'}} -
          {{fieldControl.value.substr(0, 6)}}...{{fieldControl.value.substr(fieldControl.value.length-6, fieldControl.value.length)}}]
        </span>
        <mat-icon class="gd-media-widget-configuration__exclamation-icon"
                  [class.--deleted]="isReferenceItemDeleted"
                  *ngIf="((mediaType === mediaTypes.Images || mediaType === mediaTypes.Files) && isReferenceItemDeleted)"
                  [matTooltip]="getMediaReferenceIssueTooltip(mediaType)"
                  fontSet="fal"
                  fontIcon="fa-exclamation-circle"></mat-icon>
      </div>
      <!-- Gallery deleted/unpublished reference content  -->
      <div *ngIf="mediaType === mediaTypes.Galleries"
           class="gd-media-widget-configuration__deleted-reference"
           [class.--read-only]="readOnlyMode"
           style="align-items: center; justify-content: center;">
        <mat-icon class="gd-media-widget-configuration__media-icon"
                  *ngIf="previewImageUrl && fieldControl.value"
                  svgIcon="glideCustomIcon_galleries"></mat-icon>
        <mat-icon class="gd-media-widget-configuration__placeholder-icon"
                  [class.--gallery]="mediaType === mediaTypes.Galleries"
                  *ngIf="!previewImageUrl  || !fieldControl.value"
                  svgIcon="glideCustomIcon_galleries"></mat-icon>
        <span *ngIf="!previewImageUrl && fieldControl.value"
              [matTooltip]="fieldControl?.value"
              class="gd-media-widget-configuration__deleted-reference-message"> [Gallery -
          {{fieldControl && fieldControl.value}}]
        </span>
        <mat-icon class="gd-media-widget-configuration__exclamation-icon"
                  [class.--deleted]="isReferenceItemDeleted"
                  *ngIf="isReferenceItemDeleted || (!isReferenceItemDeleted && !isGalleryPublished)"
                  [matTooltip]="getGalleryReferenceIssueTooltip()"
                  fontSet="fal"
                  fontIcon="fa-exclamation-circle"></mat-icon>
      </div>
      <div class="gd-media-widget-configuration__new-tab-button"
           *ngIf="fieldControl.value && !isReferenceItemDeleted">
        <button (click)="navigateToItem(fieldControl.value)"
                matTooltip="Open in new tab"
                i18n-matTooltip
                matTooltipPosition="below">
          <mat-icon style="opacity: 1;">open_in_new</mat-icon>
        </button>
      </div>
    </div>

    <div class="gd-media-widget-configuration__icon-buttons-wrapper"
         *ngIf="hasActionPermission">
      <button *ngIf="fieldControl.value && !readOnlyMode"
               mat-raised-button
               class="gd-media-widget-configuration__icon-remove-button"
               color="accent"
               (click)="removeItem()">{{ 'Remove' }} {{ resolveMediaType() }}
       </button>
       <button *ngIf="!readOnlyMode"
               mat-raised-button
               color="primary"
               (click)="selectMediaItem($event)">
              {{ fieldControl.value ? 'Change' : 'Select' }}  {{ resolveMediaType() }}
      </button>
    </div>
  </div>
  <div *ngIf="isFormControlRequired"
       class="gd-media-widget-configuration__error-message"
       [class.--visible]="fieldControl.hasError('required') && fieldControl.touched">
    {{ fieldConfig.label }} is required.
  </div>
</div>

<div class="gd-media-widget-configuration__multiple"
     *ngIf="fieldConfig.inputType === 'multiple' && mediaType === mediaTypes.Files">
  <div style="display: flex; flex-direction: row; align-items: center; gap: 6px;">
    <mat-form-field class="gd-media-widget-configuration__autocomplete-multi-select-wrapper"
                    *ngIf="fieldConfig.inputType === 'multiple' && !readOnlyMode"
                    [floatLabel]="readOnlyMode ? 'always' : 'auto'"
                    appearance="outline">
      <mat-label>{{ fieldConfig?.label + (isFormControlRequired ? ' *' : '') }}</mat-label>
      <input matInput
             [formControl]="filterField"
             [matAutocomplete]="multipleAuto"
             [placeholder]="!readOnlyMode ? 'Start typing Filename' : ''"
             (blur)="updateFilterFieldValidation()"
             [readonly]="readOnlyMode"
             i18n-placeholder
             autocomplete="off" />
      <mat-autocomplete autoActiveFirstOption
                        #multipleAuto="matAutocomplete"
                        (optionSelected)="selectItem($event)"
                        showPanel="filterField.value">
        <mat-option *ngFor="let option of (data$ | async)"
                    [value]="option">
          {{ option.filename }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="fieldConfig.inputType === 'multiple' && readOnlyMode"
         class="gd-field-builder__read-only-label">
         {{ fieldConfig?.label + (isFormControlRequired ? ' *' : '') }}
    </div>
    <mat-icon *ngIf="fieldConfig.description || readOnlyMode"
              class="question-icon"
              [matTooltip]="infoTooltip"
              matTooltipPosition="above"
              matSuffix
              fontSet="fal"
              fontIcon="fa-question-circle"
              style="color: #8e8e8e"></mat-icon>
    <div class="gd-media-widget-configuration__select-button-wrapper"
         *ngIf="hasActionPermission">
      <button mat-raised-button
              *ngIf="!readOnlyMode"
               color="primary"
               (click)="selectMediaItem($event)">
              Select File
      </button>
    </div>
  </div>
</div>

<section *ngIf="mediaType === mediaTypes.Files && fieldConfig.inputType === 'multiple'"
         class="gd-media-widget-configuration__card-list"
         cdkDropList
         (cdkDropListDropped)="drop($event)"
         [cdkDropListDisabled]="!hasActionPermission || readOnlyMode">
  <mat-card *ngFor="let card of multiSelectFieldChips"
            class="gd-media-widget-configuration__card-wrapper"
            [class.--deleted]="card.deleted"
            cdkDrag>
    <div class="gd-media-widget-configuration__card-filename-wrapper">
      <span class="gd-media-widget-configuration__card-name"
            [class.--no-drag]="!hasActionPermission || readOnlyMode">
        {{ card.deleted ? '[File - ' + card.id + ']' : card.filename}}
      </span>
      <span class="gd-media-widget-configuration__card-button-wrapper">
        <mat-icon *ngIf="card.deleted"
                  [matTooltip]="getMediaReferenceIssueTooltip(mediaType)"
                  fontSet="fal"
                  fontIcon="fa-exclamation-circle"
                  class="gd-media-widget-configuration__multiple-exclamation-icon"
                  [class.--deleted]="card.deleted"></mat-icon>
        <mat-icon *ngIf="!card.deleted"
                  (click)="openFileNewTab(card)"
                  matTooltip="Open in new tab"
                  i18n-matTooltip
                  class="gd-media-widget-configuration__single-new-tab-button"
                  matTooltipPosition="below">open_in_new</mat-icon>
        <mat-icon *ngIf="hasActionPermission && !readOnlyMode"
                  (click)="removeMultipleItem(card.id)"
                  class="gd-media-widget-configuration__remove-item-icon"
                  fontSet="fal"
                  fontIcon="fa-times"></mat-icon>
      </span>
    </div>
  </mat-card>
</section>
