import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Observable } from 'rxjs';
import { ConfirmComponent } from './confirm/confirm.component';
import { ComponentType } from '@angular/cdk/portal';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { ImageDownloadComponent } from '../image-download/image-download.component';
import { EmbedMediaDialogComponent } from '../embed-media-dialog/embed-media-dialog.component';
import { UploadImageMetaFormComponent } from '../upload-image-meta-form/upload-image-meta-form.component';
import { FileDownloadComponent } from '../file-download/file-download.component';
import { CreateFolderComponent } from '../create-folder/create-folder.component';
import { UploadEditFileFormComponent } from '../upload-edit-file-form/upload-edit-file-form.component';
import { MoveToComponent } from '../move-to/move-to.component';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import { EmbedFilesDialogComponent } from '../embed-files-dialog/embed-files-dialog.component';
import { SystemNotificationsComponent } from './notifications/system-notifications/system-notifications.component';
import { TripleChoiceDialogComponent, TripleChoiceDialogSelection } from './triple-choice-dialog/triple-choice-dialog.component';
import { TranslationLoaderDialogComponent } from '../../gpp-shared/content-locale-components/translation-loader-dialog/translation-loader-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class ModalsService {
  constructor(private dialog: MatDialog) {}

  public confirm(title: string, message: string, data: {} = {}, confirmButton?: string, closeButton?: string): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmComponent>;

    dialogRef = this.dialog.open(ConfirmComponent, {
      width: '375px',
      ...data,
    });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmButton = confirmButton || $localize`Yes`;
    dialogRef.componentInstance.closeButton = closeButton || $localize`No`;

    return dialogRef.afterClosed();
  }

  public tripleOptionPrompt(options: {
    title: string,
    message: string,
    dialogData?: any,
    confirmNoActionButton: string,
    confirmWithActionButton: string,
    closeButton: string,
    usage?: 'AUTOSAVE',
  }): Observable<TripleChoiceDialogSelection> {
    let dialogRef: MatDialogRef<TripleChoiceDialogComponent>;

    dialogRef = this.dialog.open(TripleChoiceDialogComponent, {
      ...(options.dialogData || {}),
      width: '425px',
    });

    dialogRef.componentInstance.title = options.title;
    dialogRef.componentInstance.message = options.message;
    dialogRef.componentInstance.confirmNoActionButton = options.confirmNoActionButton;
    dialogRef.componentInstance.confirmWithActionButton = options.confirmWithActionButton;
    dialogRef.componentInstance.closeButton = options.closeButton;
    dialogRef.componentInstance.usage = options.usage || 'AUTOSAVE';

    return dialogRef.afterClosed();
  }

  public custom<T>(title: string, component: ComponentType<T>, data: MatDialogConfig): Observable<any> {
    let dialogRef: MatDialogRef<any>;
    // autoFocus is true by default if not set, we can also set it to true if we want framework default behavior
    // we can change it to false if we want new default where nothing in the opened panel is focused by default
    // we can also pass a string css selector picking what to focus on dialog open
    // data.autoFocus = data.autoFocus || false; // in this mode nothing is focused unless explicitly set in autoFocus
    data.data = { ...data.data, title: (data.data && data.data.title) || title };
    dialogRef = this.dialog.open(component, data);
    return dialogRef.afterClosed();
  }

  public previewImage(inputData, config?, returnDialogRef = false) {
    let previewDialogRef: MatDialogRef<any>;
    const data = {
      data: { data: inputData, config: config },
      hasBackdrop: true,
      panelClass: 'gd-image-preview-panel',
      backdropClass: 'gd-image-preview-backdrop',
      maxWidth: '100vw',
      maxHeight: '100vh',
    };
    previewDialogRef = this.dialog.open(ImagePreviewComponent, data);
    if (returnDialogRef) {
      return previewDialogRef;
    }
    return previewDialogRef.afterClosed();
  }

  public downloadImage(inputData) {
    let downloadImageDialogRef: MatDialogRef<any>;
    const data = {
      data: { data: inputData },
      hasBackdrop: true,
      minWidth: '960px',
      maxWidth: '960px',
      minHeight: '300px',
      maxHeight: '585px',
      panelClass: 'gd-image-upload-panel',
    };

    downloadImageDialogRef = this.dialog.open(ImageDownloadComponent, data);
    return downloadImageDialogRef.afterClosed();
  }

  public embedImage(inputData?) {
    let embedImageDialogRef: MatDialogRef<any>;
    const modalWidth = window.innerWidth > 1218 ? '1280px' : '880px';
    const data = {
      data: { ...inputData },
      minWidth: modalWidth,
      maxWidth: modalWidth,
      minHeight: '88vh',
      maxHeight: '88vh',
      height: '100%',
      panelClass: 'gd-embed-upload-panel',
      disableClose: true,
    };

    embedImageDialogRef = this.dialog.open(EmbedMediaDialogComponent, data);
    return embedImageDialogRef.afterClosed();
  }

  public uploadImageMetaForm(inputData) {
    let uploadImageMetaDialogRef: MatDialogRef<any>;
    const data = {
      data: { data: inputData },
      // data: { ...inputData },
      minWidth: '960px',
      maxWidth: '960px',
      minHeight: '75vh',
      maxHeight: '85vh',
      height: '100%',
      panelClass: 'gd-embed-upload-panel',
      disableClose: true,
      autoFocus: false
    };

    uploadImageMetaDialogRef = this.dialog.open(UploadImageMetaFormComponent, data);
    return uploadImageMetaDialogRef.afterClosed();
  }

  public downloadUrl(inputData) {
    let downloadUrlDialogRef: MatDialogRef<any>;
    const data = {
      data: { data: inputData },
      hasBackdrop: true,
      minWidth: '960px',
      maxWidth: '960px',
      minHeight: '150px',
      maxHeight: '300px',
      panelClass: 'gd-image-upload-panel',
    };

    downloadUrlDialogRef = this.dialog.open(FileDownloadComponent, data);
    return downloadUrlDialogRef.afterClosed();
  }

  public createFolderModal(): void {
    let createFolderDialogRef: MatDialogRef<any>;
    const data = {
      hasBackdrop: true,
      minWidth: '460px',
      maxWidth: '760px',
      minHeight: '45vh',
      maxHeight: '75vh',
      panelClass: 'gd-create-new-folder-panel',
    };

    createFolderDialogRef = this.dialog.open(CreateFolderComponent, data);
    createFolderDialogRef.afterClosed();
  }

  public uploadEditFileForm(inputData) {
    let uploadEditFileDialogRef: MatDialogRef<any>;
    const data = {
      data: { data: inputData },
      hasBackdrop: true,
      minWidth: '960px',
      maxWidth: '960px',
      minHeight: '65vh',
      maxHeight: '75vh',
      panelClass: 'gd-embed-upload-panel',
    };
    uploadEditFileDialogRef = this.dialog.open(UploadEditFileFormComponent, data);
    return uploadEditFileDialogRef.afterClosed();
  }

  public moveTo(inputData, forbiddenPath) {
    let moveToDialogRef: MatDialogRef<any>;
    const data = {
      data: { data: inputData, forbiddenPath },
      hasBackdrop: true,
      minWidth: '600px',
      maxWidth: '600px',
      maxHeight: '75vh',
      panelClass: 'gd-move-to-panel',
    };

    moveToDialogRef = this.dialog.open(MoveToComponent, data);
    return moveToDialogRef.afterClosed();
  }

  public previewFile(inputData, config?, returnDialogRef = false) {
    let previewDialogRef: MatDialogRef<any>;
    const data = {
      data: { data: inputData, config: config },
      hasBackdrop: true,
      panelClass: 'gd-image-preview-panel',
      backdropClass: 'gd-image-preview-backdrop',
      maxWidth: '100vw',
      maxHeight: '100vh',
    };
    previewDialogRef = this.dialog.open(FilePreviewComponent, data);
    if (returnDialogRef) {
      return previewDialogRef;
    }
    return previewDialogRef.afterClosed();
  }

  public embedFile(inputData?) {
    let embedFileDialogRef: MatDialogRef<any>;
    const data = {
      data: { ...inputData },
      hasBackdrop: true,
      minWidth: '960px',
      maxWidth: '960px',
      minHeight: '75vh',
      maxHeight: '75vh',
      height: '100%',
      panelClass: 'gd-embed-upload-panel',
    };

    embedFileDialogRef = this.dialog.open(EmbedFilesDialogComponent, data);
    return embedFileDialogRef.afterClosed();
  }

  public info(title: string, message: string, data: any = {}): Observable<boolean> {
    let dialogRef: MatDialogRef<InfoDialogComponent>;

    dialogRef = this.dialog.open(InfoDialogComponent, {
      ...data,
      width: '320px',
      disableClose: true,
    });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;

    return dialogRef.afterClosed();
  }

  public systemNotificationOpen(message: string, label: string) {
    let systemNotificationDialogRef: MatDialogRef<SystemNotificationsComponent>;

    const data = {
      minWidth: '400px',
      maxWidth: '800px',
      maxHeight: '600px',
      disableClose: true,
    };

    systemNotificationDialogRef = this.dialog.open(SystemNotificationsComponent, data);
    systemNotificationDialogRef.componentInstance.message = message;
    systemNotificationDialogRef.componentInstance.label = label;
    return systemNotificationDialogRef.afterClosed();
  }

  public translationLoader(): MatDialogRef<TranslationLoaderDialogComponent> {
    let dialogRef: MatDialogRef<TranslationLoaderDialogComponent>;

    dialogRef = this.dialog.open(TranslationLoaderDialogComponent, {
      width: '375px',
      disableClose: true
    });
    return dialogRef;
  }

  public systemNotificationClose() {
    this.dialog.closeAll();
  }
}
