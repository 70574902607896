import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { ThemeManager } from '../../core/miscellaneous/theme-manager.service';
import { SidenavService } from '../sidenav/sidenav.service';
import { SidenavScreenSizeService } from '../sidenav/sidenav-screen-size.service';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'gd-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  private screenSizeSubscription: Subscription;
  private routerEventsSubscription: Subscription;

  sidenavOpen = true;
  sidenavMode: MatDrawerMode = 'side';
  isMobile = false;
  isSmallDisplay = false;

  sectionHasFloatingTabHeader = false;
  sidenavIsCollapsed = false;

  constructor(
    private router: Router,
    public themeManager: ThemeManager,
    private sidenavService: SidenavService,
    private sidenavScreenSizeService: SidenavScreenSizeService
  ) {
    this.routerEventsSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd ))
      .subscribe(() => this.sectionHasFloatingTabHeader = this.getSectionHasFloatingTabHeader());
  }

  ngOnInit() {
    this.screenSizeSubscription = this.sidenavScreenSizeService
      .getSizeState()
      .subscribe((screenState) => {
        const { isMobile, isSmallScreen } = screenState;
        this.sidenavService.isIconSidenav = isSmallScreen;
        this.isSmallDisplay = isSmallScreen;
        // on mobile the side nav is hidden and opens over the content
        this.isMobile = isMobile;
        this.sidenavOpen = !isMobile;
        this.sidenavMode = isMobile ? 'over' : 'side';
      });

    // for mobile mode, close the sidenav on route change, it cannot be closed otherwise
    this.routerEventsSubscription = this.router.events
      .pipe(filter(event => (this.isMobile && event instanceof NavigationEnd)))
      .subscribe(() => this.sidenav.close());


    // TODO review our LTR implementation, angular automatically adds the dir attr to document root
    //! this currently breaks the nav and some other places where we are manually adding the dir attr
    const html = document;
    document.documentElement.removeAttribute('dir');
  }

  ngOnDestroy() {
    this.screenSizeSubscription.unsubscribe();
    this.routerEventsSubscription.unsubscribe();
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  // TODO refactor this into a util fn, taking router, returning last child route snapshot
  // note: similar code used for page title in app.component
  getSectionHasFloatingTabHeader() {
    const rootRoute: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;
    // find the most specific active route and extract page title
    let childRoute = rootRoute.firstChild;
    while (childRoute) {
      // not the most specific active route, move along
      if (childRoute.firstChild) {
        childRoute = childRoute.firstChild;
        continue;
      }

      const hasCustomPageTitle = childRoute.data && childRoute.data['sectionHasFloatingTabHeader'];
      if(!hasCustomPageTitle) {
        return false;
      }

      return childRoute.data['sectionHasFloatingTabHeader'];
    }
    return false;
  }
}
