import { Taxonomy } from '../taxonomies';
import { getEmptyArticleContentModel, ContentModel } from './article-content.model';

// represent the data format recieved from the api
export interface Article {
  id: number;
  accountId: number;
  glideId: string;
  createdAt: number;
  updatedAt: number;
  overrideUpdatedAt?: boolean;
  firstPublishedAt: number;
  lastPublishedAt: number;
  latestRevision: ArticleRevision;
  autosavedRevision?: ArticleRevision;
  autosavedRevisionId?: number;
  latestRevisionId: number;
  publishedRevision?: ArticleRevision;
  scheduledRevisionId: number;
  publishedRevisionId: number;
  stagedRevisionId: number;
  articleRevisions: ArticleRevisionListItem[];
  transmitUpdatedAt: number;
  contentLocale?: any;
  localizationId: string;
  updatedBy?: number;
  lockedByUser?: number;
  createdBy: number;
  locked: boolean;
  sysCreatedAt?: number;
  sysUpdatedAt?: number;
}

export interface ArticleRevision {
  id: number;
  articleId: number;
  accountId: number;
  primaryTaxonomyId: number;
  taxonomies: Taxonomy[];
  typeId: number;
  // Time of activities
  lastPublish?: number;
  lastUpdate?: number;
  // Main content body
  body: ContentModel;
  // Article meta data
  headline: string;
  shortHeadline: string;
  catchline: string;
  standfirst: string;
  standfirstHtml: string;
  shortStandfirst: string;
  byline?: string;
  secondByline?: string;
  quotedPerson?: string;
  quotedText?: string;
  embeddedGalleryID?: number;
  promoSlug?: string;
  promoTitle?: string;
  promoSubtitle?: string;
  promoImageID?: number;
  location?: string;
  additionalItems: {
    SEO: any;
    customTypeData: any;
    promoDetails: any;
    pathConfiguration?: {
      routeId: number;
      pageId: number;
      collectionId: number;
    };
  };

  // Flags
  status: Status;
  published: boolean;
  isAutosave?: boolean;
  isUsingCustomSEO?: boolean;
  isUsingCustomMetaTagsForFacebook?: boolean;
  isUsingCustomMetaTagsForTwitter?: boolean;
  isUsingManualTemplateOrder?: boolean;
  isBreakingNews?: boolean;
  useAutomaticRelatedArticles?: boolean;
  authors: any[];
  url: string;
  glideVerify: {
    accessControl: boolean;
  },
  summary?: string;
}

export interface ArticleRevisionListItem {
  id: number;
  headline?: string;
  createdAt: number;
  published?: boolean;
}

export interface Status {
  backgroundColor: string;
  color: string;
  id: number;
  name: string;
  previewable: boolean;
  publishable: boolean;
  initial: boolean;
  published: boolean;
  scheduled: boolean;
  unpublished: boolean;
}

// this should be active article state
export interface ActiveArticleState {
  // api data
  id: number;
  glideId: string;
  createdAt: number;
  updatedAt: number;
  lastPublishedAt?: number;
  firstPublishedAt?: number;
  overrideUpdatedAt?: boolean;
  transmitUpdatedAt?: number;
  // will contain only the lates one initially
  articleRevisions: { [key: number]: any };
  // will contain id and created at date at the least
  articleRevisionsList: any[];

  // ui data
  activeRevisionId: number;
  publishedRevisionId?: number;
  scheduledRevisionId?: number;
  stagedRevisionId?: number;
  latestRevisionId?: number;
  workingRevision: ArticleRevision;
  latestRevision: ArticleRevision;
  autosavedRevision?: ArticleRevision;
  autosavedRevisionId?: number;

  localizationId?: string;
  contentLocaleId?: number;
  contentLocale?: {
    id: number,
    localeCode: string,
    label?: string,
    iconUrl?: string
  };
  localizedVersions?: any[];

  // ui flags
  loaded: boolean;
  loading: boolean;

  referencedContent: { [key: string]: any };
  relatedArticles: any[];
  relatedArticlesLoading: boolean;

  autosaving: boolean;
  autosaveError: any;
}

export function getEmptyActiveArticle({
  loaded = false,
  loading = false,
  taxonomies = [],
  typeId = null,
  contentLocaleId = null,
  contentLocale = null
}): ActiveArticleState {
  return {
    // api data
    id: null,
    glideId: null,
    contentLocale,
    contentLocaleId: contentLocale?.id || contentLocaleId,
    createdAt: null,
    updatedAt: null,
    overrideUpdatedAt: false,
    articleRevisions: {},
    articleRevisionsList: [],

    // ui data
    activeRevisionId: null,
    publishedRevisionId: null,
    latestRevision: null,
    autosavedRevision: null,
    autosavedRevisionId: null,
    scheduledRevisionId: null,

    workingRevision: {
      id: null,
      articleId: null,
      accountId: null,
      primaryTaxonomyId: null,
      taxonomies: taxonomies || [],
      typeId,
      body: getEmptyArticleContentModel(),
      headline: null,
      shortHeadline: null,
      catchline: null,
      standfirst: null,
      standfirstHtml: null,
      shortStandfirst: null,
      additionalItems: {
        SEO: {
          automaticSEO: true,
          keywords: []
        },
        customTypeData: null,
        promoDetails: null,
      },
      // Flags
      status: null,
      published: null,
      authors: [],
      url: null,
      glideVerify: {
        accessControl: null,
      }
    },

    // ui flags
    loaded,
    loading,
    referencedContent: {},
    relatedArticles: [],
    relatedArticlesLoading: false,

    // autoave
    autosaving: false,
    autosaveError: null
  };
}
