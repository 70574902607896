import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { GetTaxonomiesAction } from '../../../core/store/taxonomies/taxonomies.actions';
import { typecastAbstractToFormArray, typecastAbstractToFormGroup } from '../../../shared/shared-functions';
import { WidgetTypeFieldConfiguration } from '../../../core/store/widget-types/widget-types.model';
import { get, isEmpty, keyBy, uniq } from 'lodash-es';
import { FieldGroupsService } from '../../../core/api/field-groups/field-groups.service';
import { catchError } from 'rxjs';
import { CustomFormConstructorService } from '../form-constructor/custom-form-constructor.service';

@Component({
  selector: 'gd-dynamic-widget-form',
  templateUrl: './dynamic-widget-form.component.html',
  styleUrls: ['./dynamic-widget-form.component.scss']
})
export class DynamicWidgetFormComponent implements OnInit, OnChanges {

  @Input() formConfig;
  @Input() widgetFormGroup: UntypedFormControl;
  @Input() hasActionPermission = true;
  @Input() isEditingMode = false;
  @Input() usage: string;
  @Input() activeLocale = null;
  @Input() initialValues = null;
  typecastAbstractToFormGroup = typecastAbstractToFormGroup;
  typecastAbstractToFormArray = typecastAbstractToFormArray;

  customFieldsList: WidgetTypeFieldConfiguration[] = null;

  constructor(
    private store: Store<AppState>,
    private fc: CustomFormConstructorService,
    private customFieldGroupService: FieldGroupsService,
  ) { }

  ngOnInit() {
    this.store.dispatch(new GetTaxonomiesAction);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentFormConfig = changes['formConfig']?.currentValue;
    if(!!currentFormConfig && !!this.widgetFormGroup) {
      this.customFieldsList = currentFormConfig;
      this.initializeCfgFields();
    }
  }

  getFormFieldForKey(key, type = null) {
    if (!type) {
      return this.widgetFormGroup.get(key);
    }
    if (type === 'list') {
      return <UntypedFormArray>this.widgetFormGroup.get(key);
    }
    return <UntypedFormControl>this.widgetFormGroup.get(key);
  }

  // TODO refactor this and move most of the code into `CustomFormConstructorService` as possible
  // fetch, process, and initialize CFGs
  initializeCfgFields() {
    const cfgFields = this.formConfig.filter((f) => f.fieldType === 'group');
    const cfgIds = uniq(cfgFields.map((field) => field.dataSource.value));

    // no CFGs to initialize
    if(isEmpty(cfgIds)) {
      return;
    }

    // TODO see if this van go into effects
    this.customFieldGroupService
      .getReferencedCustomFieldGroupsByIds(cfgIds)
      .pipe(catchError(error => {
        console.error(error);
        return [];
      }))
      .subscribe((fieldGroupsData) => {
        // map fetched CFG into a dictionary by id for ease of use
        const fieldGroups = keyBy(fieldGroupsData, 'id');

        // do form init and additionalData source so that component doesn't have to do it
        this.customFieldsList = this.customFieldsList.map((field) => {
          // non CFG fields stay as is
          if (field.fieldType !== 'group') {
            return field;
          }

          // get the CFG from the API response
          const cfgId = field.dataSource.value;
          const cfg = fieldGroups[cfgId] || null;

          // TODO handle if the CFG is gone or not fetched!
          if(!cfg) {
            const syntheticError = { error: 'Cannot fetch CFG', id: cfgId };
            return { ...field, additionalDataSource: syntheticError };
          }

          const formControl: AbstractControl = this.widgetFormGroup.get(field.key);
          const initialContextValues = this.initialValues;
          const initialValues = get(this.initialValues, field.key, null);

          const isCfgList = field.inputType === 'multiple';
          // handle form fragment lists
          if (isCfgList) {
            this.fc.initializeCfgFormArray(
                cfg,
                formControl as FormArray,
                this.isEditingMode,
                this.activeLocale,
                initialValues
              );
          }

          // handle regular single form fragments (CFGs)
          if(!isCfgList) {
            this.fc.initializeCfgFormGroup(
              cfg,
              formControl as FormGroup,
              this.isEditingMode,
              this.activeLocale,
              initialValues,
              initialContextValues
            );
          }

          // return the updated cfg field config
          return {
            ...field,
            // we use this to pass along the loaded CFG config instead of it being fetched in component
            additionalDataSource: cfg,
          };
        });
      });

  }

}
