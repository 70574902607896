import { get } from 'lodash-es';
import {debounceTime,  filter, map, take } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ViewChildren,
  QueryList,
  Input,
  SimpleChanges,
  OnChanges,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppState } from '../../core/store/app-reducer';
import { Store } from '@ngrx/store';
import { UpdateQueuedImageMetaAction } from '../../core/store/images-upload/images-upload.actions';
import { Subscription, combineLatest } from 'rxjs';
import { getWorkingRevision } from '../../core/store';
import { getArticleTypes } from '../../core/store/article-type/article-type.reducer';
import { ImagesUploadService } from '../../core/api';
import { BidiService } from '../../core/i18n/bidi.service';
import { addUILocale } from '../../gpp-shared/i18n-utilities/i18n-utilities';
import { getImageSizesArray } from '../../core/store/images-configuration/images-configuration.reducer';

@Component({
  selector: 'gd-upload-image-meta-form',
  templateUrl: './upload-image-meta-form.component.html',
  styleUrls: ['./upload-image-meta-form.component.scss'],
})
export class UploadImageMetaFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() imageData = null;
  @Input() enableTagEditing = false;
  @Output() cropImage: EventEmitter<any> = new EventEmitter();
  imageMetaForm: UntypedFormGroup;
  tagsInputField = new UntypedFormControl();
  image = null;
  tagsFilter$;
  imageTags = [];
  creditInput = new UntypedFormControl();
  newCreditAdded = false;
  isStandalone = false;
  imageProperlyLoaded = false;
  imageMetaData: any = {};

  embeddedImageCredit = new UntypedFormControl();
  // usage = 'image' | 'gallery' | 'article'
  @Input() usage = 'image';
  imageMetaComponentSubscription: Subscription = new Subscription();
  isAltTextLoaded = false;
  editingMode = false;
  images = null;
  selectedImageIndex = 0;
  isImageSmallDimensions = false;
  articleImageFormats = [
    { viewValue: $localize`:Image format cue for embedded images:Default`, value: 'default' },
    { viewValue: $localize`:Image format cue for embedded images:Article Landscape`, value: 'article/landscape' },
    { viewValue: $localize`:Image format cue for embedded images:Original`, value: 'original' },
  ];

  articleImageStyles = [
    { label: $localize`:Image style cue for embedded images:Default`, value: 'default' },
    { label: $localize`:Image style cue for embedded images:Full Screen Background`, value: 'full-screen-background' },
    { label: $localize`:Image style cue for embedded images:Full Screen`, value: 'full-screen' },
  ];

  @ViewChild(MatAutocompleteTrigger) autocompleteControl;
  // @ViewChild('imageMetadataScroller', { static: true }) imageMetadataScroller;
  @ViewChild('tagsChips', { static: true }) tagsChipsList;
  @ViewChild('previewImage', { static: true }) previewImage;
  @ViewChildren('creditItem') creditItem: QueryList<any>;

  hasCroppableRenditions$ = this.store.select(getImageSizesArray).pipe(map(renditions => (renditions || []).some(r => r.cropAllowed)));

  get creditsFormArray() {
    return <UntypedFormArray>this.imageMetaForm.get('credit');
  }

  get captionControl() {
    return <UntypedFormControl>this.imageMetaForm.get('caption');
  }

  get altControl() {
    return <UntypedFormControl>this.imageMetaForm.get('alt');
  }

    dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<UploadImageMetaFormComponent>,
    private fb: UntypedFormBuilder,
    private elRef: ElementRef,
    private store: Store<AppState>,
    private imagesUploadService: ImagesUploadService,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    if (!get(this.dialogInputData, 'data.image', null)) {
      this.trackUploadProgress();
    }
    this.imageMetaForm = this.initImageMetaFormGroup();
    this.editingMode = this.dialogInputData.data && this.dialogInputData.data.isEditingMode;
    this.usage = this.dialogInputData.data?.usage || 'image';
    if (this.dialogInputData && this.dialogInputData.data && this.dialogInputData.data.image) {
      this.image = { ...this.dialogInputData.data.image };
      this.image.iptc = this.convertIPTCValue(this.image.iptc);
      this.isStandalone = true;
    }
    console.log('this image', this.image);

    if (this.usage === 'article' && this.dialogInputData && this.dialogInputData.data && this.dialogInputData.data.images) {
      this.images = this.dialogInputData.data?.images;
      this.image = { ...this.dialogInputData.data?.images[0] };
      this.checkArticleImageDimensions();
      this.isStandalone = true;
    }

    if (this.usage === 'article') {
      combineLatest([
        this.store.select(getWorkingRevision),
        this.store.select(getArticleTypes)
      ]).pipe(
        filter(([workingRev, articleTypes]) => !!workingRev && !!articleTypes),
        take(1)
      ).subscribe(([workingRev, articleTypes]) => {
        const articleType = articleTypes[workingRev.typeId];
        const customImageStyles = get(articleType, 'customImageStyles', []);
        this.articleImageStyles = [...customImageStyles];
      });
    }

    this.imageTags = this.image.tags || [];
    // Find image details meta and patch form fields
    this.patchMetaFormFields(this.image);

    // Use caption as alt if alt hasn't been set
    this.imageMetaComponentSubscription.add(
      this.captionControl.valueChanges.pipe(debounceTime(200)).subscribe(caption => {
        const shouldUseCaption =
          (this.altControl.pristine && !this.isAltTextLoaded) || !this.altControl.value;
        if (shouldUseCaption) {
          this.altControl.setValue(caption);
        }
      })
    );
  }

 /*
  ngAfterViewInit() {
    const tabsHeader = this.elRef.nativeElement.querySelector('mat-tab-header');
    const tabsHeaderHeight = +window.getComputedStyle(tabsHeader).height.replace('px', '');
    const contentHeight = this.elRef.nativeElement.offsetParent.clientHeight - tabsHeaderHeight;
    const tabsGroupEl = this.elRef.nativeElement.querySelector('.gd-upload-image-meta__tabs');
    tabsGroupEl.style.height = `${contentHeight}px`;

    const imgEl = new Image();
    imgEl.src = this.image.dataURL || this.image.thumbnail;

    imgEl.onerror = err => {
      this.image.thumbnail = this.image.original;
      imgEl.src = this.image.thumbnail;
    };

    imgEl.onload = () => {
      this.imageProperlyLoaded = true;
    };
  }
*/

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageData.currentValue) {
      this.image = { ...changes.imageData.currentValue };
      this.isStandalone = false;
    }
  }

  patchMetaFormFields(image) {
    if (!image) {
      return;
    }

    let data: any = null;
    if (image.newMeta) {
      data = { value: {} };
      Object.entries(image.newMeta).forEach(([key, value]) => data.value[key] = value);
    } else if (image.metaData) {
      data = { value: {} };
      image.metaData.forEach(({ key, value }) => (data.value[key] = value));
    } else if (image.meta) {
      data = image.meta.find(imageMeta => imageMeta.key === 'image_details');
    }
    this.imageMetaData = image.imageMetaData || data?.value || {};
    // Foreach meta key patch form field
    if (data) {
      data.value.imageFormat = data.value.imageFormat || 'default';
      data.value.imageStyle = data.value.imageStyle || 'default';
      data.value = {...data.value, caption: data.value.caption || '', credit: data.value.credit || '', alt: data.value.alt || ''}
      Object.entries(data.value).forEach(([key, value]) => {
        const imageMetaField = this.imageMetaForm.get(key);
        if (!imageMetaField) {
          return;
        }
        if (key !== 'credit') {
          return imageMetaField.patchValue(value || '');
        }
        const credits = this.formatCreditsArray(value as string);
        this.embeddedImageCredit.setValue(credits[0] || '');
        credits.forEach(credit => this.creditsFormArray.push(new UntypedFormControl(credit)));
      });
    }
    this.isAltTextLoaded = !!this.altControl.value;
  }

  saveImageUploadMeta() {
    if (this.usage === 'article') {
      this.updateImageMetaData();
      return this.dialogRef.close({ images: this.images });
    }
    if (this.isStandalone) {
      this.dialogRef.close(this.getImageMetaResponse());
    } else {
      return this.getImageMetaResponse();
    }
  }

  initImageMetaFormGroup() {
    return this.fb.group({
      caption: [''],
      credit: this.fb.array([]),
      alt: [''],
      imageFormat: ['default'],
      imageStyle: ['default'],
      label: [''],
      headline: [''],
      description: [''],
      copyright: [''],
    });
  }

  handleTagSelected(tagValue) {
    if (!tagValue || !tagValue.trim()) {
      return;
    }
    const tagExists = this.imageTags.some(tag => tag.name.toLowerCase() === tagValue.toLowerCase());
    if (!tagExists && tagValue !== '') {
      this.imageTags.unshift(({ name: tagValue, premium: false, group: 'user'}));
      this.imageTags.sort((a, b) => a.premium > b.premium ? -1 : 1);
      const addedTag = this.imageTags.find(tag => tag.name === tagValue);
      setTimeout(() => this.setAnimationForNewTag(addedTag.name), 0);
    }
  }

  setAnimationForNewTag(tagName) {
    const chipToAnimate = this.tagsChipsList.chips.find(chip => chip.value === tagName);
    const chipElement = chipToAnimate._elementRef.nativeElement;
    chipElement.classList.add('new-item');
    setTimeout(() => chipElement.classList.remove('new-item'), 500);
  }

  formatCreditsArray(credits: string) {
    if (!credits || !credits.trim()) {
      return [];
    }

    return this.isJson(credits) ? JSON.parse(credits) : [credits];
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  addCredit(credit: string) {
    this.creditInput.setValue('');
    if (!credit || !credit.trim()) {
      return;
    }
    credit = credit.trim();
    const creditExist = this.creditsFormArray.value.find(
      item => item.toLowerCase() === credit.toLowerCase()
    );
    if (creditExist) {
      return;
    }
    this.newCreditAdded = true;
    this.creditsFormArray.push(new UntypedFormControl(credit));
    setTimeout(() => (this.newCreditAdded = false), 1000);
  }

  removeCredit(index) {
    this.creditsFormArray.removeAt(index);
  }

  getImageMetaResponse() {
    const response: any = { meta: {}, tags: this.imageTags };
    response.meta = Object.entries(this.imageMetaForm.value)
      .reduce((acc, [key, value]) => {
        acc[key] = key !== 'credit' ? value : JSON.stringify(value);
        return acc;
      }, {});

    if (this.usage === 'image') {
      return response;
    }

    try {
      const credit = (this.embeddedImageCredit.value || '').trim();
      response.meta.credit = JSON.stringify(credit ? [credit] : []);
    } catch (error) {
      console.error($localize`Error occurred while processing image caption`);
      console.error(error);
    }
    return response;
  }

/*
  handleTabChanged(event) {
    if (event.tab.textLabel === 'Metadata') {
      this.imageMetadataScroller.refreshScroller();
    }
    if (event.tab.textLabel === 'Image') {
      this.imageDetailsScroller.refreshScroller();
    }
  }
*/

  handleAltFieldOnBlur() {
    if (!this.altControl.value) {
      this.altControl.setValue(this.captionControl.value.trim());
    }
  }

  changeSelectedImage() {
    this.updateImageMetaData();
    this.dialogRef.close({ changeImage: true, images: this.images });
  }

  ngOnDestroy() {
    if (!this.isStandalone) {
      const metadata = this.getImageMetaResponse();
      this.store.dispatch(
        new UpdateQueuedImageMetaAction({
          queueID: this.imageData.queueID,
          meta: metadata.meta,
          tags: metadata.tags,
        })
      );
    }
    this.imageMetaComponentSubscription.unsubscribe();
  }

  convertIPTCValue(iptc: any) {
    const isValidIPTC = iptc && Object.keys(iptc).length > 0;
    return isValidIPTC ? Object.keys(iptc).map(key => ({ key, value: iptc[key] })) : [];
  }

  setPremiumImageTag(t) {
    this.imageTags.forEach(tag => {
      if (tag.name === t.name) {
        return tag.premium = !tag.premium;
      }
      tag.premium = false;
    });
    this.imageTags.sort((a, b) => a.premium > b.premium ? -1 : 1);
  }

  updateImageMetaData() {
    let selectedImage = this.images[this.selectedImageIndex];
    selectedImage.newMeta = this.getImageMetaResponse().meta;
    this.images[this.selectedImageIndex] = selectedImage;
  }

  selectImage(index) {
    if (index !== this.selectedImageIndex) {
      this.updateImageMetaData();
      this.selectedImageIndex = index;
      this.image = this.images[index];
      this.checkArticleImageDimensions();
      this.imageTags = this.image.tags;
      this.patchMetaFormFields(this.image);
    }
  }

  navigateToItem() {
    const url = '/media/images/' + this.image.id;
    window.open(addUILocale(url), '_blank');
  }

  getPremiumTagTooltip(isPremium) {
    return isPremium
      ? $localize`Hide`
      : $localize`Mark as visible`;
  }

  trackUploadProgress() {
    this.imageMetaComponentSubscription.add(
      this.imagesUploadService
        .getUploadProgress()
        .pipe(filter((data: any) => this.image?.queueID && (data || {}).image === this.image.queueID))
        .subscribe((data: any) => (this.image.progress = +data.progress || 0))
    );
  }

  triggerCroppingProcess() {
    this.hasCroppableRenditions$.pipe(
      take(1),
      filter(cropAllowed => cropAllowed)
    ).subscribe(() => this.cropImage.emit(this.image));
  }

  formatStylePreset(str) {
    return str.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  }

  checkArticleImageDimensions() {
    const url = this.image.previewImage || this.image.dataURL || this.image.thumbnail;
    this.isImageSmallDimensions = this.image.isImageSmallDimensions || !!(typeof url === 'string' && url.includes('/original/'));
  }
}
