
import {catchError, filter, map,  mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of, forkJoin } from 'rxjs';
import { RestService } from '..';
import { GalleriesService } from '../galleries/galleries.service';
import { ImagesService } from '../images/images.service';
import { FilesService } from '../files/files.service';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';
import { Store } from '@ngrx/store';
import { getTaxonomiesByIds, getTaxonomiesLoaded } from '../../store';
import { AppState } from '../../store/app-reducer';
import { getContentLocaleById } from '../../store/content-locales/content-locales.reducer';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';

@Injectable()
export class CollectionsService {
  constructor(
    private rest: RestService,
    private galleriesService: GalleriesService,
    private imagesService: ImagesService,
    private filesService: FilesService,
    private store: Store<AppState>,
    private accountSettingsService: AccountSettingsService
  ) {}

  getCollections({ pageIndex = 0, pageSize = defaultPageSize, name = '', catchline = '', taxonomies = null,
    published = null, sysUpdatedAtFrom = null, sysUpdatedAtTo = null, updatesAvailable = false, typeIds = null, statusIds = null }) {

    let requestPath = `collections?page=${pageIndex || 0}&size=${pageSize || defaultPageSize}`;
    requestPath += published ? `&published=${published}` : '';
    requestPath += name ? `&name=${encodeURIComponent(name)}` : '';
    requestPath += catchline ? `&catchline=${encodeURIComponent(catchline)}` : '';
    requestPath += updatesAvailable ? `&updatesAvailable=${updatesAvailable}` : '';
    requestPath += sysUpdatedAtFrom ? `&sysUpdatedAtFrom=${sysUpdatedAtFrom}` : '';
    requestPath += sysUpdatedAtTo ? `&sysUpdatedAtTo=${sysUpdatedAtTo}` : '';
    if (taxonomies && taxonomies.length > 0) {
      requestPath += '&taxonomyIds=' + taxonomies.join(',');
    }
    if (typeIds && typeIds.length > 0) {
      requestPath += '&typeIds=' + typeIds.join(',');
    }
    if (statusIds && statusIds.length > 0) {
      requestPath += '&statusIds=' + statusIds.join(',');
    }

    return this.rest.get(requestPath).pipe(map(response => response));
  }

  getCollectionsByIds(collectionIds) {
    if (collectionIds.length < 1) {
        return of([]);
    }
    return this.rest.get(`collections?ids=${collectionIds.join(',')}`).pipe(
      map((collections: any) => collections.data));
  }

  getCollection(id, fetchMediaData = true) {
    return this.rest.get(`collections/${id}`).pipe(mergeMap(response => this.fetchMediaData(response, fetchMediaData)));
  }

  getCollectionRevisionById(revisionId) {
    return this.rest
    .get('collections/versions/' + revisionId).pipe(mergeMap(response => this.fetchRevisionMediaData(response)));
  }

  createCollection(payload) {
    return this.rest.post('collections', payload).pipe(mergeMap(response => this.fetchMediaData(response, true)));
  }

  updateCollection({ id, data }) {
    return this.rest.put(`collections/${id}`, data).pipe(mergeMap(response => this.fetchMediaData(response, true)));
  }

  deleteCollection(id) {
    return this.rest.delete(`collections/${id}`);
  }

  publishCollection(collectionId, revisionId) {
    return this.rest.post(`collections/${collectionId}/publish/${revisionId}`).pipe(
      map((response: any) => response));
  }

  unpublishCollection(id) {
    return this.rest.post(`collections/${id}/unpublish`);
  }

  scheduleCollection(collectionId, revisionId) {
    return this.rest.post(`collections/${collectionId}/schedule/${revisionId}`).pipe(
      map((response: any) => response));
  }

  unscheduleCollection(collectionId) {
    return this.rest.post(`collections/${collectionId}/unschedule`).pipe(
      map((response: any) => response.message));
  }

  getCollectionUrlPreview(params) {
    return this.rest
      .post('collections/url-preview/', params).pipe(
      map(response => response.data),
      map(data => data.length > 0 ? data[0].url : ''),);
  }

  fetchRevisionMediaData(response) {
    const revision = response.data;
    if (revision.items.length === 0) {
      return of(revision);
    }
    const requests = revision.items.map(item => {
      if (item.type !== 'IMAGE' && item.type !== 'GALLERY') {
        return of(item);
      }

      if (item.type === 'GALLERY') {
        return this.getGalleryData(item);
      }

      if (item.type === 'FILE') {
        return this.getFileData(item);
      }

      return this.getImageData(item);
    });
    return forkJoin(requests).pipe(map(items => ({ ...revision, items })));
  }

  fetchMediaData(response, fetchMediaData) {
    const collections = response.data;

    if (!fetchMediaData || collections.latestRevision.items.length === 0) {
      return of(collections);
    }

    const requests = collections.latestRevision.items.map(item => {
      if (item.type !== 'IMAGE' && item.type !== 'GALLERY' && item.type !== 'FILE') {
        return of(item);
      }

      if (item.type === 'GALLERY') {
        return this.getGalleryData(item);
      }

      if (item.type === 'FILE') {
        return this.getFileData(item);
      }
      return this.getImageData(item);
    });
    return forkJoin(requests).pipe(
      map(items => ({ ...collections, latestRevision: { ...collections.latestRevision, items: this.getValidItems(items) } })));
  }

  getGalleryData(item) {
    const showTaxonomyPath: boolean = this.accountSettingsService.getShowTaxonomyPathFlag();
    return this.galleriesService.getGalleryDetails((item.data && item.data.id) || item.id, true)
      .pipe(
        mergeMap((gallery) => {
          return this.store.select(getTaxonomiesLoaded).pipe(
            filter(loaded => loaded),
            take(1),
            map(()=> gallery)
          )
        }),
        mergeMap((gallery) => {
          const revisionKey = gallery.published ? 'published' : 'latest';
          return this.store.select(getTaxonomiesByIds(gallery[revisionKey].taxonomies, showTaxonomyPath, gallery.contentLocaleId)).pipe(
            take(1),
            map((taxonomies) => ({ ...gallery, taxonomies: taxonomies || [] }))
          );
        }),
        mergeMap((gallery) => {
          return this.store.select(getContentLocaleById(gallery.contentLocaleId)).pipe(
            take(1),
            map((contentLocale) => ({ ...gallery, contentLocale: contentLocale || {} }))
          );
        }),
        map((gallery: any) => {
          const revisionKey = gallery.published ? 'published' : 'latest';
          const isPublished = !!gallery.published;
          return {
            ...item,
            customData: {
              title: gallery[revisionKey].title,
              publishedAt: (isPublished && gallery[revisionKey].created_at) || null,
              updatedAt: gallery.updated_at,
              updatedBy: gallery.owners[0].user_id,
              imageCount: gallery[revisionKey] && gallery[revisionKey].images.length,
              imageURL: gallery.promoImage ? gallery.promoImage.thumbnail : '',
              isPublished,
              status: gallery[revisionKey].status,
              metaData: item.customData?.metaData || {},
              taxonomies: gallery.taxonomies || [],
              primaryTaxonomyId: gallery[revisionKey].primaryTaxonomyId,
              contentLocale: gallery.contentLocale
            },
          };
        }),
        catchError(() => {
          return this.galleriesService.getGalleryDetails((item.data && item.data.id) || item.id, false)
            .pipe(
              mergeMap((gallery) => {
                return this.store.select(getTaxonomiesLoaded).pipe(
                  filter(loaded => loaded),
                  take(1),
                  map(()=> gallery)
                )
              }),
              mergeMap((gallery) => {
                const revisionKey = gallery.published ? 'published' : 'latest';
                return this.store.select(getTaxonomiesByIds(gallery[revisionKey].taxonomies, showTaxonomyPath, gallery.contentLocaleId)).pipe(
                  take(1),
                  map((taxonomies) => ({ ...gallery, taxonomies: taxonomies || [] }))
                );
              }),
              mergeMap((gallery) => {
                return this.store.select(getContentLocaleById(gallery.contentLocaleId)).pipe(
                  take(1),
                  map((contentLocale) => ({ ...gallery, contentLocale: contentLocale || {} }))
                );
              }),
              map((gallery) => {
                const revisionKey = gallery.published ? 'published' : 'latest';
                const isPublished = !!gallery.published;
                return {
                  ...item,
                  customData: {
                    title: gallery[revisionKey].title,
                    publishedAt: (isPublished && gallery[revisionKey].created_at) || null,
                    updatedAt: gallery.updated_at,
                    updatedBy: gallery.owners[0].user_id,
                    imageCount: gallery[revisionKey] && gallery[revisionKey].images.length,
                    imageURL: gallery.promoImage ? gallery.promoImage.thumbnail : '',
                    isPublished,
                    status: gallery[revisionKey].status,
                    metaData: item.customData?.metaData || {},
                    taxonomies: gallery.taxonomies || [],
                    primaryTaxonomyId: gallery[revisionKey].primaryTaxonomyId,
                    contentLocale: gallery.contentLocale
                  },
                };
              }),
              catchError(() => of(null))
            );
        }),
      );
  }

  getImageData(item) {
    return this.imagesService.getImageAsCollectionItem(item.data.id).pipe(map(image => {
      if (!image) { return null; }
     image = this.imagesService.processImage(image);
     return {
       ...item,
       customData: {
         imageURL: image.previewImage || image.thumbnail,
         updatedAt: image.created_at,
         updatedBy: image.owners[0].user_id,
         caption: image.imageMetaData && image.imageMetaData.caption || '',
         metaData: item.customData?.metaData || {},
       },
       displayData: { isImageSmallDimensions: image.isImageSmallDimensions }
     };
   }),catchError(() => of(null)),);
  }

  getFileData(item) {
    return this.filesService.getFileAsCollectionItem(item.data.id).pipe(map(file => {
      if (!file) { return null; }
      const type = file.filename ? file.filename.toLowerCase().match(/[^.]+$/g)[0] : '';
      const fileUrl = this.filesService.resolveFileThumbnail(type, 'collection');
     return {
       ...item,
       customData: {
         fileUrl,
         updatedAt: file.created_at,
         updatedBy: file.owners[0].user_id,
         name: file.meta_data.name || file.filename || '',
         type,
         path: file.path,
         metaData: item.customData?.metaData || {},
       }
     };
   }),catchError(() => of(null)),);
  }

  getValidItems(items) {
    const validItems = items.filter(item => !!item);
    validItems.forEach((item: any, position) => item.position = position);
    return validItems;
  }

  getReferencedCollectionById(id) {
    const requestPath = `collections/${id}/basic-projection`;
    return this.rest
      .get(requestPath).pipe(
      map((collection: any) => collection.data));
  }

  getReferencedCollectionsByIds(collectionsId) {
    if (collectionsId.length < 1) {
      return of([]);
    }

    const size = collectionsId.length <= 200 ? collectionsId.length : 200;

    return this.rest.get(`collections/basic-projection?ids=${collectionsId.join(',')}&size=${size}`).pipe(
      map((collections: any) => collections.data));
  }

  getCollectionRevisionUrlPreview(revisionId) {
    return this.rest.post(`collections/url-preview/${revisionId}`).pipe(map(({ data }) => data.length > 0 ? data[0].url : ''));
  }
}
