import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { ImagesService } from '../../core/api';
import { GetUsersAction, getUsersMap } from '../../core/store/users';
import { ResetUISectionTitleStateAction } from '../../core/store/ui-section-title/ui-section-title.actions';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { Modifier, ModifierPhases } from '@popperjs/core';
import { get } from 'lodash-es';
import { BidiService } from '../../core/i18n/bidi.service';
@Component({
  selector: 'gd-section-info',
  templateUrl: './section-info.component.html',
  styleUrls: ['./section-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SectionInfoComponent implements OnInit, OnDestroy {
  @Input() sectionInfo$: Observable<any>;
  @Input() label = 'Version history';
  popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.BOTTOMSTART;
  popperTrigger: NgxPopperjsTriggers = NgxPopperjsTriggers.click;
  displayInfo$: Observable<any>;
  phase: ModifierPhases = "main";

  modifiers = [{
    name: 'offset',
    enabled: true,
    phase: this.phase,
    options: {
      offset: [20, 25],
    },
  }];
  dir$ = this.bidiService.getEffectiveLocaleDirectionality();
  constructor(
    private store: Store,
    private imagesService: ImagesService,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.displayInfo$ = combineLatest([
      this.sectionInfo$,
      this.store.select(getUsersMap)
    ]).pipe(
      tap(([sectionInfo, usersMap]) => {
        const createdBy = get(sectionInfo, 'createdBy', 0);
        const updatedBy = get(sectionInfo, 'updatedBy', 0);
        const missingUserIds = [];

        if(!!createdBy && createdBy != 1 && !usersMap[createdBy]) {
          missingUserIds.push(createdBy);
        }

        if(!!updatedBy && updatedBy != 1 && !usersMap[updatedBy]) {
          missingUserIds.push(updatedBy);
        }

        if(!!missingUserIds.length) {
          // fetch the missing users needed
          this.store.dispatch(new GetUsersAction({ ids: missingUserIds }));
        }
      }),
      debounceTime(200),
      switchMap(async ([sectionInfo, usersMap]) => {
        usersMap = { ...usersMap, 1: { id: 1, firstName: 'Superadmin', username: 'superadmin' } };
        const createdBy = await this.extractUserData(usersMap[sectionInfo.createdBy] || {});
        const updatedBy = await this.extractUserData(usersMap[sectionInfo.updatedBy] || {});
        return {
          ...sectionInfo,
          createdBy,
          updatedBy,
        };
      })
    );
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetUISectionTitleStateAction());
  }

  doesNameFitContainer(element: HTMLElement) {
    return element.scrollWidth > element.clientWidth ? false : true;
  }

  async extractUserData(user) {
    const { firstName = user.id ? 'Deactivated User' : 'Unknown', lastName = '', image } = user;
    let userImage = 'assets/img/avatars/noavatar.png';
    // validate user profile image
    if (image) {
      const imageUrl = this.imagesService.generateImageUrl(image.path);
      const isImageValid = ((await this.imagesService.validateImageUrl(imageUrl)) as any).isValid;
      userImage = isImageValid ? imageUrl : userImage;
    }
    return {
      name: firstName + ' ' + lastName,
      userImage,
    };
  }
}
