interface FoundationModelParameter {
  key: string;
  type: string;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  default?: any;
  tooltip?: string;
  group?: string;
  dataSource?: Array<any>;
}

export interface FoundationModel {
  modelId: string;
  usages: string[];
  title: string;
  description?: string;
  params: Array<FoundationModelParameter>;
  infoLink?: string;
  pricingLink?: string;
  provider: string;
}

export enum FoundationModelProvider {
  Amazon = 'Amazon',
  Anthropic = 'Anthropic',
  StabilityAI = 'StabilityAI',
  AI21Lab = 'AI21Lab',
  Meta = 'Meta',
  Cohere = 'Cohere',
  MistralAI = 'MistralAI'
}

export enum FoundationModelUsage {
  Image = 'image',
  Summary = 'summary',
  Draft = 'draft',
}

const AmazonTitanTextG1Express: FoundationModel = {
  modelId: 'amazon.titan-text-express-v1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Amazon,
  title: 'Titan Text G1 - Express | by Amazon',
  description:
    'The Amazon Titan family incorporates Amazons 25 years of experience innovating with AI and machine learning. For summaries, text generation, classification, open-ended Q&A, and information extraction.',
  infoLink: "https://aws.amazon.com/bedrock/titan/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Amazon",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0,
      tooltip:
        'Modulates the probability density function for the next tokens, implementing the temperature sampling technique. This parameter can be used to deepen or flatten the density function curve. A lower value results in a steeper curve and more deterministic responses, whereas a higher value results in a flatter curve and more random responses. The range is 0-1, and the default value is 0.',
      group: `Randomness and diversity`,
    },
    {
      key: 'topP',
      label: 'Top P',
      type: 'number',
      min: 0.1,
      max: 1,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Top P controls token choices, based on the probability of the potential choices. If you set Top P below 1.0, the model considers only the most probable options and ignores less probable options. The result is more stable and repetitive completions. The range is 0.1-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'maxTokenCount',
      label: 'Response length',
      type: 'number',
      min: 1,
      max: 4096,
      step: 1,
      default: 512,
      tooltip:
        'Configures the max number of tokens to use in the generated response. The range is 1-4096, and the default value is 512.',
      group: `Length`,
    },
    {
      key: 'stopSequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Used to make the model stop at a desired point, such as the end of a sentence or a list. The returned response will not contain the stop sequence.',
      group: `Length`,
    },
  ],
};

const AmazonTitanTextG1Lite: FoundationModel = {
  modelId: 'amazon.titan-text-lite-v1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Amazon,
  title: 'Titan Text G1 - Lite | by Amazon',
  description:
    `The Amazon Titan family incorporates Amazon's 25 years of experience innovating with AI and machine learning. Intended for fine-tuning English-language tasks, including summarisation and copywriting.`,
  infoLink: "https://aws.amazon.com/bedrock/titan/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Amazon",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0,
      tooltip:
        'Modulates the probability density function for the next tokens, implementing the temperature sampling technique. This parameter can be used to deepen or flatten the density function curve. A lower value results in a steeper curve and more deterministic responses, whereas a higher value results in a flatter curve and more random responses. The range is 0-1, and the default value is 0.',
      group: `Randomness and diversity`,
    },
    {
      key: 'topP',
      label: 'Top P',
      type: 'number',
      min: 0.1,
      max: 1,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Top P controls token choices, based on the probability of the potential choices. If you set Top P below 1.0, the model considers only the most probable options and ignores less probable options. The result is more stable and repetitive completions. The range is 0.1-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'maxTokenCount',
      label: 'Response length',
      type: 'number',
      min: 1,
      max: 4096,
      step: 1,
      default: 512,
      tooltip:
        'Configures the max number of tokens to use in the generated response. The range is 1-4096, and the default value is 512.',
      group: `Length`,
    },
    {
      key: 'stopSequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Used to make the model stop at a desired point, such as the end of a sentence or a list. The returned response will not contain the stop sequence.',
      group: `Length`,
    },
  ],
};

const AnthropicClaudeV2: FoundationModel = {
  modelId: 'anthropic.claude-v2',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Anthropic,
  title: 'Claude 2 | by Anthropic',
  description: `A large language model which can handle a range of tasks including casual dialogue, text analysis, summaries, and document question-answering.`,
  infoLink: "https://aws.amazon.com/bedrock/claude/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Anthropic",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 1,
      tooltip:
        'Tunes the degree of randomness in generation. Lower temperatures mean less random generations. The range is 0-1, and the default value is 1.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.001,
      default: 0.999,
      tooltip:
        'If set to float less than 1, only the smallest set of most probable tokens with probabilities that add up to top_p or higher are kept for generation. The range is 0-1, and the default value is 0.999.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 250,
      tooltip:
        'Can be used to reduce repetitiveness of generated tokens. The higher the value, the stronger a penalty is applied to previously present tokens, proportional to how many times they have already appeared in the prompt or prior generation. The range is 0-500, and the default value is 250.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens_to_sample',
      label: 'Maximum length',
      type: 'number',
      min: 0,
      max: 2048,
      step: 1,
      default: 300,
      tooltip:
        'Maximum number of tokens to generate. Responses are not guaranteed to fill up to the maximum desired length. The range is 0-2048, and the default value is 300.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Up to four sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.',
      group: `Length`,
    },
  ],
};

const AnthropicClaudeInstantV1: FoundationModel = {
  modelId: 'anthropic.claude-instant-v1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Anthropic,
  title: 'Claude Instant 1.2 | by Anthropic',
  description: `Claude is Anthropic's large language model which can handle a range of tasks including casual dialogue, text analysis, summaries, and document question-answering. Claude Instant built upon Claude 2, with faster responses for real-time conversation scenarios.`,
  infoLink: "https://aws.amazon.com/bedrock/claude/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Anthropic",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 1,
      tooltip:
        'Tunes the degree of randomness in generation. Lower temperatures mean less random generations. The range is 0-1, and the default value is 1.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.001,
      default: 0.999,
      tooltip:
        'If set to float less than 1, only the smallest set of most probable tokens with probabilities that add up to top_p or higher are kept for generation. The range is 0-1, and the default value is 0.999.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 250,
      tooltip:
        'Can be used to reduce repetitiveness of generated tokens. The higher the value, the stronger a penalty is applied to previously present tokens, proportional to how many times they have already appeared in the prompt or prior generation. The range is 0-500, and the default value is 250.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens_to_sample',
      label: 'Maximum length',
      type: 'number',
      min: 0,
      max: 2048,
      step: 1,
      default: 300,
      tooltip:
        'Maximum number of tokens to generate. Responses are not guaranteed to fill up to the maximum desired length. The range is 0-2048, and the default value is 300.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Up to four sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.',
      group: `Length`,
    },
  ],
};

const AnthropicClaudeV2_1: FoundationModel = {
  modelId: 'anthropic.claude-v2:1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Anthropic,
  title: 'Claude 2.1 | by Anthropic',
  description: `Claude is Anthropic's large language model which can handle a range of tasks including casual dialogue, text analysis, summaries, and document question-answering. This is an update to Claude 2.`,
  infoLink: "https://aws.amazon.com/bedrock/claude/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Anthropic",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 1,
      tooltip:
        'Tunes the degree of randomness in generation. Lower temperatures mean less random generations. The range is 0-1, and the default value is 1.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.001,
      default: 0.999,
      tooltip:
        'If set to float less than 1, only the smallest set of most probable tokens with probabilities that add up to top_p or higher are kept for generation. The range is 0-1, and the default value is 0.999.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 250,
      tooltip:
        'Can be used to reduce repetitiveness of generated tokens. The higher the value, the stronger a penalty is applied to previously present tokens, proportional to how many times they have already appeared in the prompt or prior generation. The range is 0-500, and the default value is 250.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens_to_sample',
      label: 'Maximum length',
      type: 'number',
      min: 0,
      max: 2048,
      step: 1,
      default: 300,
      tooltip:
        'Maximum number of tokens to generate. Responses are not guaranteed to fill up to the maximum desired length. The range is 0-2048, and the default value is 300.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Up to four sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.',
      group: `Length`,
    },
  ],
};


const AnthropicClaudeV3Sonnet: FoundationModel = {
  modelId: 'anthropic.claude-3-sonnet-20240229-v1:0',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Anthropic,
  title: 'Claude 3 Sonnet | by Anthropic',
  description: `The latest Claude 3 series really let you go in-depth into subjects and turn the heat up on the complexity of question posed versus earlier Claude models, and in multiple languages to boot. Claude 3 Sonnet can process images and return text outputs,and enter very large queries and query sets.`,
  infoLink: "https://aws.amazon.com/bedrock/claude/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Anthropic",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 1,
      tooltip:
        'Tunes the degree of randomness in generation. Lower temperatures mean less random generations. The range is 0-1, and the default value is 1.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.001,
      default: 0.999,
      tooltip:
        'If set to float less than 1, only the smallest set of most probable tokens with probabilities that add up to top_p or higher are kept for generation. The range is 0-1, and the default value is 0.999.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 250,
      tooltip:
        'Can be used to reduce repetitiveness of generated tokens. The higher the value, the stronger a penalty is applied to previously present tokens, proportional to how many times they have already appeared in the prompt or prior generation. The range is 0-500, and the default value is 250.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 0,
      max: 4096,
      step: 1,
      default: 2000,
      tooltip:
        'Maximum number of tokens to generate. Responses are not guaranteed to fill up to the maximum desired length. The range is 0-4096, and the default value is 2000.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Up to four sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.',
      group: `Length`,
    },
  ],
};

const AnthropicClaudeV3Haiku: FoundationModel = {
  modelId: 'anthropic.claude-3-haiku-20240307-v1:0',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Anthropic,
  title: 'Claude 3 Haiku | by Anthropic',
  description: `Claude 3 Haiku is aimed to beat its forerunners for near-instant responsiveness. It answers simple queries and requests with speed.`,
  infoLink: "https://aws.amazon.com/bedrock/claude/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Anthropic",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 1,
      tooltip:
        'Tunes the degree of randomness in generation. Lower temperatures mean less random generations. The range is 0-1, and the default value is 1.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.001,
      default: 0.999,
      tooltip:
        'If set to float less than 1, only the smallest set of most probable tokens with probabilities that add up to top_p or higher are kept for generation. The range is 0-1, and the default value is 0.999.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 250,
      tooltip:
        'Can be used to reduce repetitiveness of generated tokens. The higher the value, the stronger a penalty is applied to previously present tokens, proportional to how many times they have already appeared in the prompt or prior generation. The range is 0-500, and the default value is 250.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 0,
      max: 4096,
      step: 1,
      default: 2000,
      tooltip:
        'Maximum number of tokens to generate. Responses are not guaranteed to fill up to the maximum desired length. The range is 0-4096, and the default value is 2000.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Up to four sequences where the API will stop generating further tokens. The returned text will not contain the stop sequence.',
      group: `Length`,
    },
  ],
};

const AI21LabsJurassic2Mid: FoundationModel = {
  modelId: 'ai21.j2-mid-v1',
  provider: FoundationModelProvider.AI21Lab,
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  title: 'Jurassic-2 Mid | by AI21 Labs',
  description: `Jurassic-2 Mid is AI21's mid-sized model, carefully designed to strike the right balance between exceptional quality and affordability. Jurassic-2 Mid can be applied to any language comprehension or generation task including question answering, summaries, long-form copy generation, advanced information extraction etc.`,
  infoLink: "https://aws.amazon.com/bedrock/jurassic/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#AI21_Labs",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.7,
      tooltip:
        'Controls sampling randomness. Increasing the temperature tends to result in more varied and creative completions, whereas decreasing it produces more stable and repetitive completions. A temperature of zero effectively disables random sampling and makes the completions deterministic. The range is 0-1, and the default value is 0.7.',
      group: `Randomness and diversity`,
    },
    {
      key: 'topP',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 1,
      tooltip:
        'The percentile of probability from which tokens are sampled. A value lower than 1.0 means that only the corresponding top percentile of options are considered, and that less likely options will not be generated, resulting in more stable and repetitive completions. The range is 0-1, and the default value is 1.',
      group: `Randomness and diversity`,
    },
    {
      key: 'maxTokens',
      label: 'Max completion length',
      type: 'number',
      min: 0,
      max: 8191,
      step: 1,
      default: 200,
      tooltip:
        'By setting a maximum number of tokens to generate, you can limit the length of the model output. The range is 0-8191, and the default value is 200.',
      group: `Length`,
    },
    {
      key: 'stopSequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Generation is terminated as soon as the model generates any of the stop sequences. Choosing appropriate stop sequences can help you avoid generating unnecessary tokens.',
      group: `Length`,
    },
    {
      key: 'presencePenalty',
      label: 'Presence penalty',
      type: 'number',
      min: 0,
      max: 5,
      step: 0.1,
      default: 0,
      tooltip:
        'Reduce the probability of generating new tokens that appeared at least once in the prompt or in the completion. The range is 0-5, and the default value is 0.',
      group: `Repetitions`,
    },
    {
      key: 'countPenalty',
      label: 'Count penalty',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0,
      tooltip:
        'Reduce the probability of generating new tokens that appeared in the prompt or in the completion, proportional to the number of appearances. The range is 0-1, and the default value is 0.',
      group: `Repetitions`,
    },
    {
      key: 'frequencyPenalty',
      label: 'Frequency penalty',
      type: 'number',
      min: 0,
      max: 500,
      step: 0.1,
      default: 0,
      tooltip:
        'Reduce the probability of generating new tokens that appeared in the prompt or in the completion, proportional to the frequency of their appearances in the text (normalized to text length). The range is 0-500, and the default value is 0.',
      group: `Repetitions`,
    },
    {
      key: 'specialTokens',
      label: 'Penalize special tokens',
      type: 'array-custom',
      default: [],
      tooltip:
        'The penalties above are applied to all tokens except the special categories listed below. For example, the model is allowed to repeat punctuation tokens such as `‘`.`‘` (period). Select one or more of the special categories to apply the penalties to those tokens too.',
      group: ``,
      dataSource: [
        { key: 'applyToEmojis', label: 'Emojis' },
        { key: 'applyToNumbers', label: 'Numbers' },
        { key: 'applyToPunctuations', label: 'Punctuations' },
        { key: 'applyToStopwords', label: 'Stopwords' },
        { key: 'applyToWhitespaces', label: 'Whitespaces' },
      ],
    },
  ],
};


const AI21LabsJurassic2Ultra: FoundationModel = {
  modelId: 'ai21.j2-ultra-v1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.AI21Lab,
  title: 'Jurassic-2 Ultra | by AI21 Labs',
  description: `For complex tasks that require advanced text generation and comprehension. Popular use cases include question answering, summaries, long-form copy generation, advanced information extraction, and more.`,
  infoLink: "https://aws.amazon.com/bedrock/jurassic/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#AI21_Labs",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.7,
      tooltip:
        'Controls sampling randomness. Increasing the temperature tends to result in more varied and creative completions, whereas decreasing it produces more stable and repetitive completions. A temperature of zero effectively disables random sampling and makes the completions deterministic. The range is 0-1, and the default value is 0.7.',
      group: `Randomness and diversity`,
    },
    {
      key: 'topP',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 1,
      tooltip:
        'The percentile of probability from which tokens are sampled. A value lower than 1.0 means that only the corresponding top percentile of options are considered, and that less likely options will not be generated, resulting in more stable and repetitive completions. The range is 0-1, and the default value is 1.',
      group: `Randomness and diversity`,
    },
    {
      key: 'maxTokens',
      label: 'Max completion length',
      type: 'number',
      min: 0,
      max: 8191,
      step: 1,
      default: 200,
      tooltip:
        'By setting a maximum number of tokens to generate, you can limit the length of the model output. The range is 0-8191, and the default value is 200.',
      group: `Length`,
    },
    {
      key: 'stopSequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        'Generation is terminated as soon as the model generates any of the stop sequences. Choosing appropriate stop sequences can help you avoid generating unnecessary tokens.',
      group: `Length`,
    },
    {
      key: 'presencePenalty',
      label: 'Presence penalty',
      type: 'number',
      min: 0,
      max: 5,
      step: 0.1,
      default: 0,
      tooltip:
        'Reduce the probability of generating new tokens that appeared at least once in the prompt or in the completion. The range is 0-5, and the default value is 0.',
      group: `Repetitions`,
    },
    {
      key: 'countPenalty',
      label: 'Count penalty',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0,
      tooltip:
        'Reduce the probability of generating new tokens that appeared in the prompt or in the completion, proportional to the number of appearances. The range is 0-1, and the default value is 0.',
      group: `Repetitions`,
    },
    {
      key: 'frequencyPenalty',
      label: 'Frequency penalty',
      type: 'number',
      min: 0,
      max: 500,
      step: 0.1,
      default: 0,
      tooltip:
        'Reduce the probability of generating new tokens that appeared in the prompt or in the completion, proportional to the frequency of their appearances in the text (normalized to text length). The range is 0-500, and the default value is 0.',
      group: `Repetitions`,
    },
    {
      key: 'specialTokens',
      label: 'Penalize special tokens',
      type: 'array-custom',
      default: [],
      tooltip:
        'The penalties above are applied to all tokens except the special categories listed below. For example, the model is allowed to repeat punctuation tokens such as `‘`.`‘` (period). Select one or more of the special categories to apply the penalties to those tokens too.',
      group: ``,
      dataSource: [
        { key: 'applyToEmojis', label: 'Emojis' },
        { key: 'applyToNumbers', label: 'Numbers' },
        { key: 'applyToPunctuations', label: 'Punctuations' },
        { key: 'applyToStopwords', label: 'Stopwords' },
        { key: 'applyToWhitespaces', label: 'Whitespaces' },
      ],
    },
  ],
};

const MetaLlama2Chat13B: FoundationModel = {
  modelId: 'meta.llama2-13b-chat-v1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Meta,
  title: 'Llama 2 Chat 13B | by Meta',
  description: `A chatty variant of Llama 2 models. Llama 2 is intended for commercial and research use in English. The 13b refers to the 13 billion parameters in the base model.`,
  infoLink: "https://aws.amazon.com/bedrock/llama/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Meta_Llama",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.5,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-1, and the default value is 0.5.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. The range is 0-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_gen_len',
      label: 'Max generation length',
      type: 'number',
      min: 1,
      max: 2048,
      step: 1,
      default: 512,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The model truncates the response once the generated text exceeds max_gen_len. The range is 1-2048, and the default value is 512.',
      group: `Length`,
    }
  ],
};

const MetaLlama2Chat70B: FoundationModel = {
  modelId: 'meta.llama2-70b-chat-v1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Meta,
  title: 'Llama 2 Chat 70B | by Meta',
  description: `A chatty variant of Llama 2 models, but 'cleverer' than 13b by dint of its larger dataset base. Llama 2 is intended for commercial and research use in English. The 70b refers to the 70 billion parameters in the base model.`,
  infoLink: "https://aws.amazon.com/bedrock/llama/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Meta_Llama",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.5,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-1, and the default value is 0.5.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. The range is 0-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_gen_len',
      label: 'Max generation length',
      type: 'number',
      min: 1,
      max: 2048,
      step: 1,
      default: 512,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The model truncates the response once the generated text exceeds max_gen_len. The range is 1-2048, and the default value is 512.',
      group: `Length`,
    }
  ],
};

const StableDiffusionXL: FoundationModel = {
  modelId: 'stability.stable-diffusion-xl',
  usages: [FoundationModelUsage.Image],
  provider: FoundationModelProvider.StabilityAI,
  title: 'Deprecated - Stable Diffusion XL v0.8 | by Stability AI',
  description: `A step forward in the lineage of Stability's image generation models.`,
  params: [
    {
      key: 'promptStrength',
      label: 'Prompt strength',
      type: 'number',
      min: 0,
      max: 30,
      step: 0.1,
      default: 10,
      tooltip:
        'Determines how much the final image portrays the prompt. random generations. The range is 0-30, and the default value is 10.',
      group: '',
    },
    {
      key: 'generationStep',
      label: 'Generation step',
      type: 'number',
      min: 10,
      max: 150,
      step: 1,
      default: 50,
      tooltip:
        'Generation step determines how many times the image is sampled. More steps can result in a more accurate result. The range is 10-150, and the default value is 50.',
      group: '',
    },
    {
      key: 'seed',
      label: 'Seed',
      type: 'number',
      min: 0,
      max: 4294967295,
      step: 1,
      default: 0,
      tooltip:
        'The seed determines the initial noise setting. If you use the same seed and the same settings as a previous run, inference creates a similar image. The seed value is a random number.',
      group: '',
    },
  ],
};
const StableDiffusionXLV1: FoundationModel = {
  modelId: 'stability.stable-diffusion-xl-v1',
  usages: [FoundationModelUsage.Image],
  provider: FoundationModelProvider.StabilityAI,
  title: 'Stable Diffusion XL v1.0 | by Stability AI',
  description: `Targeted at things historically difficult for image models to render, such as hands and text or spatially arranged compositions (e.g., someone in the background chasing a dog in the foreground).`,
  params: [
    {
      key: 'promptStrength',
      label: 'Prompt strength',
      type: 'number',
      min: 0,
      max: 30,
      step: 0.1,
      default: 10,
      tooltip:
        'Determines how much the final image portrays the prompt - random generations. The range is 0-30, and the default value is 10.',
      group: '',
    },
    {
      key: 'generationStep',
      label: 'Generation step',
      type: 'number',
      min: 10,
      max: 150,
      step: 1,
      default: 50,
      tooltip:
        'Generation step determines how many times the image is sampled. More steps can result in a more accurate result. The range is 10-150, and the default value is 50.',
      group: '',
    },
    {
      key: 'seed',
      label: 'Seed',
      type: 'number',
      min: 0,
      max: 4294967295,
      step: 1,
      default: 0,
      tooltip:
        'The seed determines the initial noise setting. If you use the same seed and the same settings as a previous run, inference creates a similar image. The seed value is a random number.',
      group: '',
    },
  ],
};

const AmazonTitanImageGeneratorG1: FoundationModel = {
  modelId: 'amazon.titan-image-generator-v1',
  usages: [FoundationModelUsage.Image],
  provider: FoundationModelProvider.Amazon,
  title: 'Titan Image Generator G1 v1.0 | by Amazon',
  description: `Titan's image generation model.`,
  params: [
    {
      key: 'promptStrength',
      label: 'Prompt strength',
      type: 'number',
      min: 1.1,
      max: 10,
      step: 0.1,
      default: 8.0,
      tooltip:
        'Determines how much the final image portrays the prompt - random generations. The range is 1.1-10 and the default value is 8.',
      group: '',
    },
    {
      key: 'seed',
      label: 'Seed',
      type: 'number',
      min: 0,
      max: 2147483646,
      step: 1,
      default: 0,
      tooltip:
        'The seed determines the initial noise setting. If you use the same seed and the same settings as a previous run, inference creates a similar image. The seed value is a random number.',
      group: '',
    },
  ],
};

const CohereCommandTextV14: FoundationModel = {
  modelId: 'cohere.command-text-v14',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Cohere,
  title: 'Command | by Cohere',
  description: `Command is Cohere's flagship text generation model. It is trained to follow user commands and be useful in practical business applications.`,
  infoLink: "https://aws.amazon.com/bedrock/cohere-command/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Cohere",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 5,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-5, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.75,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. If both p and k are enabled, p acts after k. The range is 0-1, and the default value is 0.75.',
      group: `Randomness and diversity`,
    },
    {
      key: 'k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 0,
      tooltip:
        'Specify the number of token choices the model uses to generate the next token. If both p and k are enabled, p acts after k. The range is 0-500, and the default value is 0.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 1,
      max: 4096,
      step: 1,
      default: 400,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The range is 1-4096, and the default value is 400.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        `Configure up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.`,
      group: `Length`,
    },
    {
      key: 'return_likelihoods',
      label: 'Return likelihoods',
      type: 'select',
      default: 'NONE',
      tooltip:
        `One of GENERATION|ALL|NONE to specify how and if the token likelihoods are returned with the response. Defaults to NONE.`,
      group: `Length`,
      dataSource: [
        { key: 'ALL', label: 'All' },
        { key: 'NONE', label: 'None' },
        { key: 'GENERATION', label: 'Generation' },
      ],
    },
  ],
};

const CohereCommandLightTextV14: FoundationModel = {
  modelId: 'cohere.command-light-text-v14',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Cohere,
  title: 'Command Light | by Cohere',
  description: `Cohere's Command-Light is a generative model that responds well with instruction-like prompts.`,
  infoLink: "https://aws.amazon.com/bedrock/cohere-command/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Cohere",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 5,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-5, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.75,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. If both p and k are enabled, p acts after k. The range is 0-1, and the default value is 0.75.',
      group: `Randomness and diversity`,
    },
    {
      key: 'k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 0,
      tooltip:
        'Specify the number of token choices the model uses to generate the next token. If both p and k are enabled, p acts after k. The range is 0-500, and the default value is 0.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 1,
      max: 4096,
      step: 1,
      default: 400,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The range is 1-4096, and the default value is 400.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        `Configure up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.`,
      group: `Length`,
    },
    {
      key: 'return_likelihoods',
      label: 'Return likelihoods',
      type: 'select',
      default: 'NONE',
      tooltip:
        `One of GENERATION|ALL|NONE to specify how and if the token likelihoods are returned with the response. Defaults to NONE.`,
      group: `Length`,
      dataSource: [
        { key: 'ALL', label: 'All' },
        { key: 'NONE', label: 'None' },
        { key: 'GENERATION', label: 'Generation' },
      ],
    },
  ],
};

const MistralAIText7BInstruct: FoundationModel = {
  modelId: 'mistral.mistral-7b-instruct-v0:2',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.MistralAI,
  title: 'Mistral 7B Instruct | by Mistral AI',
  description: `A 7B dense Transformer, fast-deployed and easily customisable. Small, yet powerful for a variety of use cases. Supports English and code, and a 32k context window.`,
  infoLink: "https://aws.amazon.com/bedrock/mistral/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Mistral_AI",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.5,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-1, and the default value is 0.5.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.9,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. If both p and k are enabled, p acts after k. The range is 0-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 200,
      step: 1,
      default: 200,
      tooltip:
        'Specify the number of token choices the model uses to generate the next token. If both p and k are enabled, p acts after k. The range is 0-200, and the default value is 200.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 1,
      max: 8192,
      step: 1,
      default: 512,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The range is 1-8192, and the default value is 512.',
      group: `Length`,
    },
    {
      key: 'stop',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        `Configure up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.`,
      group: `Length`,
    }
  ],
};

const MixtralAIText8x7BInstruct: FoundationModel = {
  modelId: 'mistral.mixtral-8x7b-instruct-v0:1',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.MistralAI,
  title: 'Mixtral 8x7B Instruct | by Mistral AI',
  description: `A 7B sparse Mixture-of-Experts model with stronger capabilities than Mistral 7B. Uses 12B active parameters out of 45B total. Supports multiple languages, code and 32k context window.`,
  infoLink: "https://aws.amazon.com/bedrock/mistral/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Mistral_AI",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.5,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-1, and the default value is 0.5.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.9,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. If both p and k are enabled, p acts after k. The range is 0-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 200,
      step: 1,
      default: 200,
      tooltip:
        'Specify the number of token choices the model uses to generate the next token. If both p and k are enabled, p acts after k. The range is 0-200, and the default value is 200.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 1,
      max: 4096,
      step: 1,
      default: 512,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The range is 1-4096, and the default value is 512.',
      group: `Length`,
    },
    {
      key: 'stop',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        `Configure up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.`,
      group: `Length`,
    }
  ],
};

const MistralLarge: FoundationModel = {
  modelId: 'mistral.mistral-large-2402-v1:0',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.MistralAI,
  title: 'Mistral Large | by Mistral AI',
  description: `The most advanced Mistral AI Large Language model capable of handling any language task including complex multilingual reasoning, text understanding, transformation, and code generation.`,
  infoLink: "https://aws.amazon.com/bedrock/mistral/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Mistral_AI",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.5,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-1, and the default value is 0.5.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.9,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. If both p and k are enabled, p acts after k. The range is 0-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 200,
      step: 1,
      default: 200,
      tooltip:
        'Specify the number of token choices the model uses to generate the next token. If both p and k are enabled, p acts after k. The range is 0-200, and the default value is 200.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 1,
      max: 4096,
      step: 1,
      default: 512,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The range is 1-4096, and the default value is 512.',
      group: `Length`,
    },
    {
      key: 'stop',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        `Configure up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.`,
      group: `Length`,
    }
  ],
};

const MetaLlama38BInstruct: FoundationModel = {
  modelId: 'meta.llama3-8b-instruct-v1:0',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Meta,
  title: 'Llama 3 8B Instruct | by Meta',
  description: `Meta Llama 3 8B is ideal for limited computational power and resources, faster training times, and edge devices. The model excels at text summarization, text classification, sentiment analysis, and language translation.`,
  infoLink: "https://aws.amazon.com/bedrock/llama/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Meta_Llama",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.5,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-1, and the default value is 0.5.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. The range is 0-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_gen_len',
      label: 'Max generation length',
      type: 'number',
      min: 1,
      max: 2048,
      step: 1,
      default: 512,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The model truncates the response once the generated text exceeds max_gen_len. The range is 1-2048, and the default value is 512.',
      group: `Length`,
    }
  ],
};

const MetaLlama370BInstruct: FoundationModel = {
  modelId: 'meta.llama3-70b-instruct-v1:0',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Meta,
  title: 'Llama 3 70B Instruct | by Meta',
  description: `Meta Llama 3 70B is ideal for content creation, conversational AI, language understanding, research development, and enterprise applications. The model excels at text summarization and accuracy, text classification and nuance, sentiment analysis and nuance reasoning, language modeling, dialogue systems, code generation, and following instructions.`,
  infoLink: "https://aws.amazon.com/bedrock/llama/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Meta_Llama",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.5,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-1, and the default value is 0.5.',
      group: `Randomness and diversity`,
    },
    {
      key: 'top_p',
      label: 'Top P',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      default: 0.9,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. The range is 0-1, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_gen_len',
      label: 'Max generation length',
      type: 'number',
      min: 1,
      max: 2048,
      step: 1,
      default: 512,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The model truncates the response once the generated text exceeds max_gen_len. The range is 1-2048, and the default value is 512.',
      group: `Length`,
    }
  ],
};

const CohereCommandR: FoundationModel = {
  modelId: 'cohere.command-r-v1:0',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Cohere,
  title: 'Command R | by Cohere',
  description: `Command R is a generative language model optimized for long-context tasks and large scale production workloads.`,
  infoLink: "https://aws.amazon.com/bedrock/cohere-command/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Cohere",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.75,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-5, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'p',
      label: 'Top P',
      type: 'number',
      min: 0.01,
      max: 0.99,
      step: 0.01,
      default: 0.01,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. If both p and k are enabled, p acts after k. The range is 0-1, and the default value is 0.75.',
      group: `Randomness and diversity`,
    },
    {
      key: 'k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 0,
      tooltip:
        'Specify the number of token choices the model uses to generate the next token. If both p and k are enabled, p acts after k. The range is 0-500, and the default value is 0.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 1,
      max: 4000,
      step: 1,
      default: 400,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The range is 1-4096, and the default value is 400.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        `Configure up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.`,
      group: `Length`,
    }
  ],
};

const CohereCommandRPlus: FoundationModel = {
  modelId: 'cohere.command-r-plus-v1:0',
  usages: [FoundationModelUsage.Summary, FoundationModelUsage.Draft],
  provider: FoundationModelProvider.Cohere,
  title: 'Command R+ | by Cohere',
  description: `Command R+ is a highly performant generative language model optimized for large scale production workloads.`,
  infoLink: "https://aws.amazon.com/bedrock/cohere-command/",
  pricingLink: "https://aws.amazon.com/bedrock/pricing/#Cohere",
  params: [
    {
      key: 'temperature',
      label: 'Temperature',
      type: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      default: 0.75,
      tooltip:
        'Use a lower value to decrease randomness in the response. The range is 0-5, and the default value is 0.9.',
      group: `Randomness and diversity`,
    },
    {
      key: 'p',
      label: 'Top P',
      type: 'number',
      min: 0.01,
      max: 0.99,
      step: 0.01,
      default: 0.01,
      tooltip:
        'Use a lower value to ignore less probable options. Set to 0 or 1.0 to disable. If both p and k are enabled, p acts after k. The range is 0-1, and the default value is 0.75.',
      group: `Randomness and diversity`,
    },
    {
      key: 'k',
      label: 'Top K',
      type: 'number',
      min: 0,
      max: 500,
      step: 1,
      default: 0,
      tooltip:
        'Specify the number of token choices the model uses to generate the next token. If both p and k are enabled, p acts after k. The range is 0-500, and the default value is 0.',
      group: `Randomness and diversity`,
    },
    {
      key: 'max_tokens',
      label: 'Maximum length',
      type: 'number',
      min: 1,
      max: 4000,
      step: 1,
      default: 400,
      tooltip:
        'Specify the maximum number of tokens to use in the generated response. The range is 1-4096, and the default value is 400.',
      group: `Length`,
    },
    {
      key: 'stop_sequences',
      label: 'Stop sequences',
      type: 'array',
      default: [],
      tooltip:
        `Configure up to four sequences that the model recognizes. After a stop sequence, the model stops generating further tokens. The returned text doesn't contain the stop sequence.`,
      group: `Length`,
    }
  ],
};


export const FoundationModels: Array<FoundationModel> = [
  StableDiffusionXL,
  StableDiffusionXLV1,
  AmazonTitanImageGeneratorG1,
  AmazonTitanTextG1Express,
  AmazonTitanTextG1Lite,
  AnthropicClaudeInstantV1,
  AnthropicClaudeV2,
  AnthropicClaudeV2_1,
  AnthropicClaudeV3Sonnet,
  AnthropicClaudeV3Haiku,
  AI21LabsJurassic2Mid,
  AI21LabsJurassic2Ultra,
  MetaLlama2Chat13B,
  MetaLlama2Chat70B,
  MetaLlama38BInstruct,
  MetaLlama370BInstruct,
  CohereCommandTextV14,
  CohereCommandLightTextV14,
  CohereCommandR,
  CohereCommandRPlus,
  MistralAIText7BInstruct,
  MixtralAIText8x7BInstruct,
  MistralLarge
];
