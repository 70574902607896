<div class="gd-embed-widget-configuration-wrapper">
    <section *ngIf="widgets$ | async as widgets">
        <mat-form-field class="gd-embed-widget-dialog__field"
                        appearance="outline"
                        floatLabel="always">
            <mat-label style="text-transform: capitalize;" i18n>{is3rdPartyWidget, select, true {third party widgets} false {system widgets} }</mat-label>
            <input matInput
                   placeholder="Start typing the widget name"
                   i18n-placeholder
                   [formControl]="filterField"
                   [matAutocomplete]="auto"
                   (keydown.enter)="$event.preventDefault()">
            <mat-autocomplete autoActiveFirstOption
                              #auto="matAutocomplete"
                              [displayWith]="displayFn"
                              (optionSelected)="changeWidget($event.option.value)">
                <mat-option *ngFor="let widget of dataSource$ | async"
                            [value]="widget">
                    {{ widget.name }}
                </mat-option>
            </mat-autocomplete>
            <mat-error i18n="Validation message">Widget is required</mat-error>
        </mat-form-field>
        <p *ngIf="widgets.length === 0"
           style="margin-top: 16px" i18n>
           There are no {is3rdPartyWidget, select, true {third party widgets} false {system widgets} } which are embeddable into articles.
        </p>
    </section>

    <section *ngIf="embeddableForm.get('id').value">
        <ng-container *ngIf="widgetFormConfig; else noConfigRequired">
            <div [style.margin-bottom]="'10px'" i18n>Widget Data</div>
            <gd-dynamic-widget-form [formConfig]="widgetFormConfig"
                                    [widgetFormGroup]="configurationControl"
                                    [isEditingMode]="!loadDefaultValues"
                                    [initialValues]="initialValues"
                                    [usage]="usage"
                                    [activeLocale]="activeLocale">
            </gd-dynamic-widget-form>
        </ng-container>
        <ng-template #noConfigRequired>
            <div [style.margin-bottom]="'10px'" i18n>No configuration needed.</div>
        </ng-template>
    </section>
</div>
