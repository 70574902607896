import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { ModalsService } from '../../../shared/modals/modals.service';
import { AppState } from '../../store/app-reducer';
import { AccountChangeAction, BeforeAccountChangeAction } from '../../store/auth/auth.actions';
import { AuthService } from '../auth/auth.service';

export interface AccountSwitchingConfiguration {
  modalTitle: string;
  modalMessage: string;
  resourceForm: UntypedFormGroup;
  accountId: number;
  redirectRoute: string;
}
@Injectable({ providedIn: 'root' })

export class AccountSwitchingService {
  private beforeSwitchingTimeout = null;
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private modalService: ModalsService,
  ) { }

  private activateTimer(accountId: number, timeout: number) {
    clearTimeout(this.beforeSwitchingTimeout)
    this.beforeSwitchingTimeout = setTimeout(() => {
      this.switchAccount(accountId);
    }, timeout);
  }

  public beforeSwitchAccount(accountId) {
    this.activateTimer(accountId, 200);
    this.store.dispatch(new BeforeAccountChangeAction({accountId}));
  }

  public switchAccount(accountId, route = 'dashboard') {
    this.authService
      .refreshAuthToken(accountId).pipe(tap(() => {
        this.store.dispatch(new AccountChangeAction({accountId, route}));
      })).subscribe();
  }

  public clearTimeout() {
    clearTimeout(this.beforeSwitchingTimeout);
  }

  // in case that confirmAccountSwitchAndNavigate doesn't confirm good enough implementation when checking
  // if the form is dirty or not we can call  unsavedChangesDialog inside specific component and create custom logic
  public unsavedChangesDialog(modalTitle: string, modalMessage: string) {
    return this.modalService.confirm(modalTitle, modalMessage).pipe(filter(v => !!v));
  }

  public confirmAccountSwitchAndNavigate(configuration: AccountSwitchingConfiguration) {
    const { modalTitle, modalMessage, resourceForm, accountId, redirectRoute } = configuration;
    // stop switching to default page (dashboard page)
    this.clearTimeout();
    if(!resourceForm.dirty) {
      // switch account and redirect to defined page
      return this.switchAccount(accountId, redirectRoute);
    }

    this.unsavedChangesDialog(modalTitle, modalMessage).subscribe(() => {
      // disable unsaved changes modal
      resourceForm.markAsPristine();
      // switch account and redirect to defined page
      this.switchAccount(accountId, redirectRoute);
    });
  }
}
