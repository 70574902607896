import {
  GET_ARTICLE, GET_ARTICLE_COMPLETE, UPDATE_ARTICLE,
  UPDATE_ARTICLE_COMPLETE, CREATE_ARTICLE, CREATE_ARTICLE_COMPLETE,
  SWITCH_ACTIVE_REVISION, CREATE_NEW_EMPTY_ARTICLE, RESET_ACTIVE_ARTICLE,
  UPDATE_ARTICLE_BODY, UPDATE_ARTICLE_CONTENT_NODE, SET_ACTIVE_ARTICLE_TAXONOMIES,
  SWITCH_ACTIVE_REVISION_COMPLETE, SET_ARTICLE_LOADING_FLAG, UPDATE_REFERENCED_CONTENT, UPDATE_ARTICLE_STATUS,
  UPDATE_RELATED_ARTICLES, SET_PRIMARY_TAXONOMY, PUBLISH_ARTICLE_COMPLETE,
  REMOVE_ARTICLE_CONTENT_NODE, REMOVE_AUTHOR, ADD_AUTHOR, SET_IS_USING_AUTOMATIC_RELATED_ARTICLES,
  ADD_RELATED_ARTICLE, REMOVE_RELATED_ARTICLE, SET_ARTICLE_TYPE, PUBLISH_ARTICLE, COPY_ARTICLE_CONTENT_NODES,
  MANAGE_EMBEDDED_IMAGE_VISIBILITY, CLEAR_REFERENCED_CONTENT, CREATE_ARTICLE_CONTENT_NODE, SCHEDULE_ARTICLE_SUCCESS,
  LOAD_LOCALIZED_VERSIONS, LOCALIZE_ARTICLE_SUCCESS, LOCALIZE_ARTICLE
} from './article.actions';
import { ActiveArticleState, getEmptyActiveArticle, Article, ArticleRevision } from './article.model';
import { createSelector } from 'reselect';
import { UnsafeAction } from '../unsafe-action.interface';
import { EmbeddedType, ReferencedContentType } from './article-content.model';
import { UNPUBLISH_ARTICLE_COMPLETE } from './article.actions';
import { articleContentReducer } from './article-content.reducer';
import { AppState } from '../app-reducer';
import { prepareReferencedContent } from '../utility-functions/prepare-referenced-content';
import { ARTICLE_AUTOSAVE_SUCCESS, DISCARD_ARTICLE_AUTOSAVE_SUCCESS, UNSCHEDULE_ARTICLE_SUCCESS, SET_RELATED_ARTICLES_LOADING_FLAG, ARTICLE_AUTOSAVE, TRANSLATE_ARTICLE, ARTICLE_ACTION_FAILED } from '..';
import { cloneDeep, keyBy } from 'lodash-es';

const initialState: ActiveArticleState = getEmptyActiveArticle({});

const embeddedTypes = Object.values(EmbeddedType);
const referencedContentTypes = Object.values(ReferencedContentType);

export function articleReducer(state: ActiveArticleState = initialState, action: UnsafeAction) {
  switch (action.type) {

    case SET_ACTIVE_ARTICLE_TAXONOMIES: {
      const taxonomies = [...action.payload];
      const workingRevision: any = { ...state.workingRevision, taxonomies };
      const isValidPrimaryTaxonomy = !!taxonomies.find(tax => tax.id === workingRevision.primaryTaxonomyId);
      if (!isValidPrimaryTaxonomy) {
        workingRevision.primaryTaxonomyId = (taxonomies.find(tax => tax.sectionPage) || {}).id || null;
      }
      return { ...state, workingRevision };
    }

    case LOCALIZE_ARTICLE:
    case GET_ARTICLE: {
      return getEmptyActiveArticle({ loading: true });
    }

    case CREATE_ARTICLE:
    case UPDATE_ARTICLE:
    case PUBLISH_ARTICLE:
    case TRANSLATE_ARTICLE: {
      return {
        ...state,
        loading: true,
      };
    }


    case UPDATE_ARTICLE_COMPLETE:
    case CREATE_ARTICLE_COMPLETE:
    case LOCALIZE_ARTICLE_SUCCESS:
    case GET_ARTICLE_COMPLETE: {
      const articleData: Article = action.payload;
      const latestRevision: ArticleRevision = articleData.latestRevision;

      const newArticleRevisionsMap = {
        ...state.articleRevisions,
        [latestRevision.id]: latestRevision
      };

      // NOT dependant on if the autosave is enabled, do load autosave revision if it is there
      const hasAutosave = !!articleData.autosavedRevision;
      // clone the working revision since we will be changing it
      const activeRevision = cloneDeep(hasAutosave ? articleData.autosavedRevision : latestRevision);

      if(hasAutosave) {
        const autosavedRevisionId = articleData.autosavedRevision.id;
        newArticleRevisionsMap[autosavedRevisionId] = articleData.autosavedRevision;
      }

      return {
        ...state,
        id: articleData.id,
        glideId: articleData.glideId,
        createdAt: articleData.createdAt,
        updatedAt: articleData.updatedAt,
        contentLocale: articleData.contentLocale,
        localizationId: articleData.localizationId,
        overrideUpdatedAt: articleData.overrideUpdatedAt,
        firstPublishedAt: articleData.firstPublishedAt,
        lastPublishedAt: articleData.lastPublishedAt,
        workingRevision: activeRevision,
        autosavedRevision: articleData.autosavedRevision,
        autosavedRevisionId: articleData.autosavedRevision?.id || null,
        activeRevisionId: activeRevision.id,
        latestRevisionId: articleData.latestRevisionId,
        publishedRevisionId: articleData.publishedRevisionId,
        scheduledRevisionId: articleData.scheduledRevisionId,
        stagedRevisionId: articleData.stagedRevisionId,
        articleRevisionsList: articleData.articleRevisions,
        articleRevisions: newArticleRevisionsMap,
        loaded: true,
        loading: false,
        transmitUpdatedAt: articleData.transmitUpdatedAt,
      };
    }

    case ARTICLE_AUTOSAVE: {
      return {
        ...state,
        autosaving: true
      }
    }

    case ARTICLE_AUTOSAVE_SUCCESS: {
      const articleData: Article = action.payload;
      const autosavedRevision: ArticleRevision = articleData.autosavedRevision;
      const autosavedRevisionId = articleData.autosavedRevisionId;
      const articleRevisionsList = articleData.articleRevisions;

      const newRevisionsMap = {
        ...state.articleRevisions,
        [autosavedRevisionId]: autosavedRevision
      };

      // update active revision
      return {
        ...state,
        autosavedRevision,
        autosavedRevisionId,
        articleRevisionsList,
        articleRevisions: newRevisionsMap,
        activeRevisionId: autosavedRevisionId,
        autosaving: false,
        autosaveError: null,
      };
    }

    case DISCARD_ARTICLE_AUTOSAVE_SUCCESS: {
      const discardedRevisionId = action.payload;
      // if there was no autosave revision to discard, this is a NOOP
      if(!discardedRevisionId) {
        return state;
      }

      const articleRevisionsList = state.articleRevisionsList.filter(revision => {
        return revision.id === discardedRevisionId ? false : true;
      })

      const newArticleRevisionsMap = { ...state.articleRevisions };
      delete newArticleRevisionsMap[discardedRevisionId];

      // TODO debug discard autosave revision issues
      // update active revision
      return {
        ...state,
        activeRevisionId: state.latestRevisionId,
        autosavedRevisionId: null,
        autosavedRevision: null,
        autosaving: false,
        autosaveError: null,
        articleRevisionsList: articleRevisionsList,
        articleRevisions: newArticleRevisionsMap,
      };
    }

    case LOAD_LOCALIZED_VERSIONS: {
      return {
        ...state,
        localizedVersions: action.payload.localizedVersions
      }
    }

    case SWITCH_ACTIVE_REVISION: {
      const newActiveRevisionId = action.payload;
      return {
        ...state,
        loading: true,
        activeRevisionId: newActiveRevisionId,
      };
    }

    case SWITCH_ACTIVE_REVISION_COMPLETE: {
      const selectedRevision: ArticleRevision = action.payload;

      const autosavedRevisionId = state.autosavedRevisionId;

      // if we don't have an autosave version, then just switch the working revision
      // also if we're switching to the autosave revision: don't delete it
      if(!autosavedRevisionId || autosavedRevisionId === selectedRevision.id) {
        return {
          ...state,
          loading: false,
          workingRevision: selectedRevision,
          articleRevisions: {
            ...state.articleRevisions,
            [selectedRevision.id]: selectedRevision
          },
        };
      }

      // if we have an autosave revision, remove autosave revision if we're not
      // switching to the autosave revision specifically
      const articleRevisions = {
        ...state.articleRevisions,
        [selectedRevision.id]: selectedRevision
      }
      delete articleRevisions[autosavedRevisionId];
      const articleRevisionsList = state.articleRevisionsList
        .filter(revision => revision.id !== autosavedRevisionId);

      return {
        ...state,
        articleRevisions,
        articleRevisionsList,
        loading: false,
        autosavedRevision: null,
        autosavedRevisionId: null,
        workingRevision: selectedRevision,
        autosaving: false,
        autosaveError: null,
      };
    }

    case CREATE_NEW_EMPTY_ARTICLE:
      return getEmptyActiveArticle(action.payload);

    case RESET_ACTIVE_ARTICLE: {
      return getEmptyActiveArticle({});
    }

    case UPDATE_ARTICLE_BODY: {
      const workingRevision = articleContentReducer(state.workingRevision, action);
      return {
        ...state,
        workingRevision,
      };
    }

    case CREATE_ARTICLE_CONTENT_NODE:
    case UPDATE_ARTICLE_CONTENT_NODE: {
      const workingRevision = articleContentReducer(state.workingRevision, action);
      const targetBlockId = action.payload.blockId || action.payload.nodeId;
      const targetBlock: any = workingRevision.body.contentNodes[targetBlockId];

      if (!targetBlock) {
        return state;
      }
      const referencedContent = referencedContentTypes.includes(targetBlock.type)
        ? prepareReferencedContent(state.referencedContent, targetBlock)
        : state.referencedContent;

      return {
        ...state,
        workingRevision,
        referencedContent,
      };
    }

    case COPY_ARTICLE_CONTENT_NODES: {
      const workingRevision = articleContentReducer(state.workingRevision, action);
      const newRefCont = action.payload.referencedContent || {};
      const referencedContent = { ...state.referencedContent, ...newRefCont };
      return {
        ...state,
        workingRevision,
        referencedContent
      };
    }

    case REMOVE_ARTICLE_CONTENT_NODE: {
      const workingRevision = articleContentReducer(state.workingRevision, action);
      return { ...state, workingRevision };
    }

    case UPDATE_REFERENCED_CONTENT: {
      return {
        ...state,
        referencedContent: {
          ...state.referencedContent,
          ...action.payload
        },
      };
    }

    case MANAGE_EMBEDDED_IMAGE_VISIBILITY: {
      const data = action.payload;
      const affectedNode = state.referencedContent[data.id];
      if (!affectedNode || affectedNode.dataId !== data.dataId) {
        // stop executing image checker
        clearTimeout(data.timeout);
        return state;
      }
      return {
        ...state,
        referencedContent: {
          ...state.referencedContent,
          [data.id]: { ...affectedNode, src: data.src || affectedNode.src, isNotLoaded: data.isNotLoaded }
        }
      };
    }

    case CLEAR_REFERENCED_CONTENT: {
      return { ...state, referencedContent: {} };
    }
    // TODO Fix reference on new working revision
    case UNPUBLISH_ARTICLE_COMPLETE: {
      const publishRevisionId = state.publishedRevisionId;
      const newArticleRevisionsMap = state.articleRevisions;
      const newWorkingRevision = newArticleRevisionsMap[publishRevisionId];
      newWorkingRevision.status = action.payload;
      newArticleRevisionsMap[publishRevisionId] = { ...newWorkingRevision };


      const newArticlesList = [...state.articleRevisionsList];
      newArticlesList.forEach(article => {
        if (article.id === publishRevisionId) {
          article.status = action.payload;
          article.statusLabel = article.status.label;
        }
      });

      return {
        ...state,
        publishedRevisionId: null,
        workingRevision: newWorkingRevision,
        articleRevisions: newArticleRevisionsMap,
        articleRevisionsList: newArticlesList,
        transmitUpdatedAt: null,
        overrideUpdatedAt: false,
      };
    }

    case SET_ARTICLE_LOADING_FLAG: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_RELATED_ARTICLES_LOADING_FLAG: {
      return {
        ...state,
        relatedArticlesLoading: action.payload
      };
    }

    case UPDATE_ARTICLE_STATUS: {
      return {
        ...state,
        workingRevision: {
          ...state.workingRevision,
          status: { ...action.payload }
        }
      };
    }

    case UPDATE_RELATED_ARTICLES: {
      const relatedArticles = action.payload;
      return {
        ...state,
        relatedArticlesLoading: false,
        relatedArticles
      };
    }

    case SET_PRIMARY_TAXONOMY: {
      const primaryTaxonomyId = action.payload;
      return {
        ...state,
        workingRevision: { ...state.workingRevision, primaryTaxonomyId }
      };
    }

    case SET_IS_USING_AUTOMATIC_RELATED_ARTICLES: {
      const useAutomaticRelatedArticles = action.payload;
      return {
        ...state,
        workingRevision: { ...state.workingRevision, useAutomaticRelatedArticles }
      };
    }

    case ADD_RELATED_ARTICLE: {
      const articleExist = state.relatedArticles.find(article => article.id === action.payload.id);
      const relatedArticles = articleExist ? state.relatedArticles : [...state.relatedArticles, action.payload];
      return { ...state, relatedArticles };
    }

    case REMOVE_RELATED_ARTICLE: {
      const relatedArticles = state.relatedArticles.filter(article => article.id !== action.payload);
      return { ...state, relatedArticles };
    }

    case PUBLISH_ARTICLE_COMPLETE: {
      const newPublishedRevision = action.payload.publishedRevisionId;
      const oldPublishedRevision = state.publishedRevisionId;
      const newArticleRevisionsMap = state.articleRevisions;
      if (oldPublishedRevision) {
        delete newArticleRevisionsMap[oldPublishedRevision];
      }
      const newWorkingRevision = { ...newArticleRevisionsMap[newPublishedRevision] };
      const oldArticleStatus = { ...(action.payload.unpublishedState || newWorkingRevision.status) };
      const newArticleStatus = action.payload.publishedState;
      newWorkingRevision.status = newArticleStatus;
      newArticleRevisionsMap[newPublishedRevision] = { ...newWorkingRevision };

      const newArticlesList = [...state.articleRevisionsList];
      newArticlesList.forEach(article => {
        if (article.id === newPublishedRevision) {
          article.status = newArticleStatus;
          article.statusLabel = article.status.label;
          return;
        }

        if (oldPublishedRevision && oldPublishedRevision === article.id) {
          article.status = oldArticleStatus;
          article.statusLabel = article.status.label;
        }
      });

      return {
        ...state,
        id: action.payload.id,
        glideId: action.payload.glideId,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
        firstPublishedAt: action.payload.firstPublishedAt,
        lastPublishedAt: action.payload.lastPublishedAt,
        workingRevision: newWorkingRevision,
        publishedRevisionId: action.payload.publishedRevisionId,
        stagedRevisionId: action.payload.stagedRevisionId,
        activeRevisionId: action.payload.publishedRevisionId,
        articleRevisions: newArticleRevisionsMap,
        loaded: true,
        loading: false,
        articleRevisionsList: newArticlesList,
        transmitUpdatedAt: action.payload.transmitUpdatedAt,
        overrideUpdatedAt: false,
      };
    }

    case SCHEDULE_ARTICLE_SUCCESS: {
      const newScheduledRevision = action.payload.scheduledRevisionId;
      const oldScheduledRevision = state.scheduledRevisionId;
      const newArticleRevisionsMap = state.articleRevisions;
      if (oldScheduledRevision && newScheduledRevision !== oldScheduledRevision) {
        delete newArticleRevisionsMap[oldScheduledRevision];
      }

      const newWorkingRevision = { ...newArticleRevisionsMap[newScheduledRevision] };
      const oldArticleStatus = { ...newWorkingRevision.status };
      const newArticleStatus = action.payload.scheduledState;
      newWorkingRevision.status = newArticleStatus;
      newArticleRevisionsMap[newScheduledRevision] = { ...newWorkingRevision };


      const newArticlesList = [...state.articleRevisionsList];
      newArticlesList.forEach(article => {
        if (article.id === newScheduledRevision) {
          article.status = newArticleStatus;
          article.statusLabel = article.status.label;
          return;
        }

        if (oldScheduledRevision && oldScheduledRevision === article.id) {
          article.status = oldArticleStatus;
          article.statusLabel = article.status.label;
        }
      });

      return {
        ...state,
        id: action.payload.id,
        glideId: action.payload.glideId,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
        firstPublishedAt: action.payload.firstPublishedAt,
        lastPublishedAt: action.payload.lastPublishedAt,
        workingRevision: newWorkingRevision,
        scheduledRevisionId: action.payload.scheduledRevisionId,
        stagedRevisionId: action.payload.stagedRevisionId,
        activeRevisionId: action.payload.scheduledRevisionId,
        articleRevisions: newArticleRevisionsMap,
        loaded: true,
        loading: false,
        articleRevisionsList: newArticlesList
      };
    }
    // TODO Fix reference on new working revision
    case UNSCHEDULE_ARTICLE_SUCCESS: {
      const scheduledRevisionId = state.scheduledRevisionId;
      const newArticleRevisionsMap = state.articleRevisions;
      const newWorkingRevision = newArticleRevisionsMap[scheduledRevisionId];
      newWorkingRevision.status = action.payload;
      newArticleRevisionsMap[scheduledRevisionId] = { ...newWorkingRevision };


      const newArticlesList = [...state.articleRevisionsList];
      newArticlesList.forEach(article => {
        if (article.id === scheduledRevisionId) {
          article.status = action.payload;
          article.statusLabel = article.status.label;
        }
      });

      return {
        ...state,
        scheduledRevisionId: null,
        workingRevision: newWorkingRevision,
        articleRevisions: newArticleRevisionsMap,
        articleRevisionsList: newArticlesList
      };
    }

    case ADD_AUTHOR: {
      const authorExist = state.workingRevision.authors.find(author => author.id === action.payload.id);
      const authors = authorExist ? state.workingRevision.authors : [...state.workingRevision.authors, action.payload];
      return {
        ...state,
        workingRevision: {
          ...state.workingRevision,
          authors
        }
      };
    }

    case REMOVE_AUTHOR: {
      const authors = state.workingRevision.authors.filter(author => author.id !== action.payload);
      return {
        ...state,
        workingRevision: {
          ...state.workingRevision,
          authors
        }
      };
    }

    case SET_ARTICLE_TYPE: {
      const typeId = action.payload;
      return {
        ...state,
        workingRevision: {
          ...state.workingRevision,
          typeId
        }
      };
    }

    case ARTICLE_ACTION_FAILED: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }

}

export const getActiveArticleState = createSelector(
  (state: AppState) => state.activeArticle,
  (activeArticleState): ActiveArticleState => activeArticleState
);

export const getActiveArticleContentLocale = createSelector(
  getActiveArticleState,
  (activeArticleState) => activeArticleState.contentLocale
)

export const getActiveArticleLocalizedVersions = createSelector(
  getActiveArticleState,
  (activeArticleState) => activeArticleState.localizedVersions
)

export const getActiveArticleLocale = createSelector(
  getActiveArticleState,
  (activeArticleState) => activeArticleState.contentLocale
)

export const getActiveArticleLocalizationId = createSelector(
  getActiveArticleState,
  (activeArticleState) => activeArticleState.localizationId
)

export const isActiveArticleLoaded = createSelector(
  getActiveArticleState,
  (activeArticleState: ActiveArticleState) => {
    return activeArticleState.loaded && !activeArticleState.loading;
  }
);

export const getActiveArticleSelectedRevisionId = createSelector(
  getActiveArticleState,
  activeArticle => activeArticle.activeRevisionId
);

export const getActiveArticlePublishedRevisionId = createSelector(
  getActiveArticleState,
  activeArticle => activeArticle.publishedRevisionId
);

export const isActiveArticleRevisionPublished = createSelector(
  getActiveArticleSelectedRevisionId,
  getActiveArticlePublishedRevisionId,
  (activeRevisionId, publishedRevisionId) => activeRevisionId && publishedRevisionId && activeRevisionId === publishedRevisionId
);

export const getAutosaveRevisionId = createSelector(getActiveArticleState, (activeArticle) => {
  return activeArticle?.autosavedRevisionId || null;
})

export const getWorkingRevision = createSelector(getActiveArticleState, (activeArticle) => {
  if (!activeArticle || !activeArticle.workingRevision) {
    return null;
  }
  return activeArticle.workingRevision;
});

export const getActiveArticleRevisions = createSelector(getActiveArticleState, (activeArticle: ActiveArticleState) => {
  if (!activeArticle.articleRevisionsList) {
    return [];
  }
  return activeArticle.articleRevisionsList;
});

export const getActiveRevisionContentBlocks = createSelector(
  getActiveArticleState,
  (activeArticle: ActiveArticleState) => activeArticle.workingRevision.body
);


export const getContentNode = (id: string) => createSelector(getActiveArticleState, (activeArticle) => {
  if (!id) { return null; }
  const contentNodes = activeArticle.workingRevision.body.contentNodes;
  // TODO validate this post ng9 update
  const contentNode = { ...(Object.values(contentNodes).find((node: any) => node.id === id) as any) };
  const refEl = activeArticle.referencedContent ? Object.values(activeArticle.referencedContent).find((node: any) => node.id === id) : null;
  if (!refEl) {
    return contentNode;
  }
  Object.keys(refEl).filter(key => !contentNode.hasOwnProperty(key)).forEach(key => contentNode[key] = refEl[key]);
  return contentNode;
});

export const getReferencedContentNode = (id: string) => createSelector(getActiveArticleState, (activeArticle) => {
  if (!id) { return null; }
  return Object.values(activeArticle.referencedContent).find((node: any) => node.id === id);
});

export const getReferencedContent = createSelector(getActiveArticleState, activeArticle => activeArticle.referencedContent);

export const getGlideArticleId = createSelector(getActiveArticleState, (activeArticle) => activeArticle.glideId);

export const getFirstEmbeddedImage = createSelector(getActiveArticleState, (activeArticle) => {
  const articleBodyExist = activeArticle.workingRevision && activeArticle.workingRevision.body;
  const referencedContentExist = Object.keys(activeArticle.referencedContent).length > 0;
  if (!articleBodyExist || !referencedContentExist) {
    return '';
  }

  const body = activeArticle.workingRevision.body;
  const node: any = Object.values(body.contentNodes).find((item: any) => item.type === EmbeddedType.Image);
  if (!node) {
    return '';
  }
  const image = activeArticle.referencedContent[node.id] || {};

  return image.src || '';
});

export const getRelatedArticles = createSelector(getActiveArticleState, activeArticle => {
  return activeArticle.relatedArticles;
});

export const getArticleAuthors = createSelector(getActiveArticleState, activeArticle => activeArticle.workingRevision.authors);

export const getArticleUrlPreview = createSelector(getActiveArticleState, activeArticle => activeArticle.workingRevision.url);

export const getArticleLoadedFlag = createSelector(getActiveArticleState, state => state.loaded);

export const getArticleLoadingFlag = createSelector(getActiveArticleState, state => state.loading);

export const isArticleAutosaving = createSelector(getActiveArticleState, state => state.autosaving);

export const getRelatedArticlesLoadingFlag = createSelector(getActiveArticleState, state => state.relatedArticlesLoading);

export const getContentNodesArray = createSelector(getWorkingRevision, workingRevision => {
  return workingRevision ? Object.values(workingRevision.body.contentNodes) : [];
});

export const getActiveArticleTransmitUpdatedAt = createSelector(
  getActiveArticleState,
  (activeArticle) => activeArticle.transmitUpdatedAt || null
);
