import { WidgetType } from './widget-types.model';
import { UnsafeAction } from '../unsafe-action.interface';
import {
  GET_WIDGET_TYPES, GET_WIDGET_TYPES_SUCCESS, GET_WIDGET_TYPES_ERROR,
  CREATE_WIDGET_TYPE_SUCCESS, CREATE_WIDGET_TYPE, DELETE_WIDGET_TYPE,
  DELETE_WIDGET_TYPE_SUCCESS,
  UPDATE_WIDGET_TYPE,
  UPDATE_WIDGET_TYPE_SUCCESS,
  SET_PAGE_VIEW_OPTIONS,
  SET_WIDGET_TYPES_FILTER,
  CLEAR_WIDGET_TYPES
} from './widget-types.actions';
import { createSelector } from 'reselect';
import { defaultPageSize, defaultPageSizeOptions } from '../constants/default-pagination.constants';

export interface WidgetTypesState {
  loaded: boolean;
  loading: boolean;
  error: string;
  items: WidgetType[];
  widgetTypesView?: {};
  filter: string;
}

const initialState: WidgetTypesState = {
  loaded: false,
  loading: false,
  error: null,
  items: [],
  widgetTypesView: {
    currentPage: 0,
    total: 0,
    pageSize: defaultPageSize,
    pageSizeOptions: defaultPageSizeOptions
  },
  filter: '',
};

export function widgetTypesReducer(state: WidgetTypesState = initialState, action: UnsafeAction) {

  switch (action.type) {

    case GET_WIDGET_TYPES: {
      const widgetTypes = action.payload;
      return {
          ...state,
          loading: true,
          widgetTypesView: initialState.widgetTypesView,
          filter: ''
        };
    }

    case GET_WIDGET_TYPES_SUCCESS: {
      const widgetTypes = action.payload;
      // Ensure that all widget types have valid updatedAt property
      widgetTypes.filter(item => !item.updatedAt).forEach(item => item.updatedAt = 0);
      widgetTypes.sort((item1, item2) => item1.id < item2.id ? 1 : -1 )
                 .sort((item1, item2) => item1.updatedAt < item2.updatedAt ? 1 : -1);

      return {
        loaded: true,
        loading: false,
        error: null,
        items: widgetTypes,
        widgetTypesView: {
          ...state.widgetTypesView,
          total: action.payload.length
        },
        filter: ''
      };
    }

    case UPDATE_WIDGET_TYPE:
    case CREATE_WIDGET_TYPE: {
      return { ...state, loading: true };
    }

    case CREATE_WIDGET_TYPE_SUCCESS: {
      const newType = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        items: [...state.items, newType]
      };
    }

    case DELETE_WIDGET_TYPE_SUCCESS: {
      const deletedId = action.payload;
      const total = state.widgetTypesView['total'];
      return {
        ...state,
        loading: false,
        error: null,
        items: state.items.filter(wt => wt.id !== deletedId),
        widgetTypesView: {
          ...state.widgetTypesView,
          total: total - 1,
        },
      };
    }

    case UPDATE_WIDGET_TYPE_SUCCESS: {
      const updatedWidgetType: WidgetType = action.payload;
      const updatedIndex = state.items.findIndex(wt => wt.id === updatedWidgetType.id);
      const newItemsArray = [
        ...state.items.slice(0, updatedIndex),
        updatedWidgetType,
        ...state.items.slice(updatedIndex + 1)
      ];

      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        items: newItemsArray
      };
    }

    case GET_WIDGET_TYPES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case SET_PAGE_VIEW_OPTIONS: {
      return {
        ...state,
        widgetTypesView: {
          ...state.widgetTypesView,
          currentPage: action.payload.pageIndex,
          pageSize: action.payload.pageSize
        }
      };
    }

    case SET_WIDGET_TYPES_FILTER: {
      const filter = typeof action.payload.filter === 'string' ? action.payload.filter.trim().toLowerCase() : '';
      const total = state.items.filter(item => item.name.toLowerCase().includes(filter)).length;
      const currentPage = action.payload.currentPage;
      return {
        ...state,
        widgetTypesView: {
          ...state.widgetTypesView,
          currentPage,
          total
        },
        filter
      };
    }
    case CLEAR_WIDGET_TYPES: {
      return {
        ...state,
        items: [],
        widgetTypesView: {
          pageSize: 0,
          currentPage: 0,
          total: 0
        },
      }
    }
    default: {
      return state;
    }
  }

}

export const getWidgetTypesState = (state) => state.widgetTypes;
export const getWidgetTypesList = createSelector(getWidgetTypesState, wts => wts.items);
export const getWidgetTypeById = (id) => {
  return createSelector(getWidgetTypesList, wts => wts && wts.find(wt => wt.id === id));
};
export const getWidgetTypesLoaded = createSelector(getWidgetTypesState, wts => wts.loaded);
export const getWidgetTypesLoading = createSelector(getWidgetTypesState, wts => wts.loading);
export const getWidgetPageView = createSelector(getWidgetTypesState, state => state.widgetTypesView);

export const getFilteredWidgetTypesList = createSelector(getWidgetTypesState, (state): WidgetType[] => {
  const widgetTypesArray = state.items;
  if (widgetTypesArray.length && !state.loading && state.loaded) {
    const filteredWidgetTypes = state.items.filter(item => item.name.toLowerCase().includes(state.filter));
    const startIndex = state.widgetTypesView.currentPage * state.widgetTypesView.pageSize;
    const endIndex = startIndex + state.widgetTypesView.pageSize;
    return filteredWidgetTypes.slice(startIndex, endIndex);
  }
  return [];
});


export const getSortedWidgets = createSelector(getWidgetTypesList, list => {
  return list.sort((a, b) => sortByName(a, b)).sort((a, b) => sortByIs3PartyFlag(a, b));
});

function sortByIs3PartyFlag(a, b) {
  const x = a.thirdParty ? 1 : 0;
  const y = b.thirdParty ? 1 : 0;
  return x - y;
}

function sortByName(a, b) {
  const x = a.name.toLowerCase();
  const y = b.name.toLowerCase();
    const res = (x < y) ? -1 : ((x > y) ? 1 : 0);
  return res;
}
