import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { combineLatest, iif, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../core/store/app-reducer';
import {
  ClearWidgetTypesAction,
  GetWidgetTypesAction,
} from '../../core/store/widget-types/widget-types.actions';
import {
  getWidgetTypesList,
  getWidgetTypesLoaded,
} from '../../core/store/widget-types/widget-types.reducer';
import { CustomFormConstructorService } from '../../gpp-shared/widget-configuration/form-constructor/custom-form-constructor.service';
import { DataSourceOptions } from '../../gpp-shared/custom-form-builder/data-source-options.enum';
import { ContentType } from '../froala-embed-widget/froala-embed-widget.component';

@Component({
  selector: 'gd-embed-widget-configuration',
  templateUrl: './embed-widget-configuration.component.html',
  styleUrls: ['./embed-widget-configuration.component.scss'],
})
export class EmbedWidgetConfigurationComponent implements OnInit, OnDestroy {
  @Input() embeddableForm: UntypedFormGroup;
  @Input() widgetId: number;
  @Input() contentType: ContentType;
  @Input() is3rdPartyWidget: boolean = false;
  @Input() dialogInputDataConfiguration = {};
  @Input() blockId: string;
  @Input() allowedWidgetsIdsFromConfig: number[] = [];
  @Input() usage: string;
  @Input() activeLocale = null;

  componentSub: Subscription = new Subscription();

  loadDefaultValues: boolean = true;
  widgetFormConfig;
  initialValues = null;

  activeWidget;
  filterField: UntypedFormControl = new UntypedFormControl('');
  allowedWidgetTypes = [];
  dataSource$: Observable<any> = of([]);
  widgets$: Observable<any> = this.getWidgetsData();

  get displayFn() {
    return (option) => {
      if (!option) {
        return '';
      }
      if (typeof option === 'object') {
        return option ? option.name : '';
      }
      let data;
      this.componentSub.add(this.widgets$.pipe(take(1)).subscribe((res) => (data = res)));
      const selected = data.find((item) => item.id === option) || {};
      return selected.name || this.activeWidget.name || '';
    };
  }

  get configurationControl() {
    return <UntypedFormControl>this.embeddableForm.get('configuration');
  }

  constructor(
    private store: Store<AppState>,
    private fc: CustomFormConstructorService
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetWidgetTypesAction({ thirdParty: this.is3rdPartyWidget }));
    this.componentSub.add(
      this.filterField.valueChanges.pipe(debounceTime(300)).subscribe((filterValue) => {

        if (typeof filterValue !== 'string') {
          return;
        }

        if (!filterValue) {
          this.dataSource$ = of(this.allowedWidgetTypes);
          if (this.embeddableForm.get('id').value) {
            this.embeddableForm.reset();
            this.filterField.reset();
            this.activeWidget = null;
          }
          return;
        }
        const filteredWidgets = this.allowedWidgetTypes.filter((widget) =>
          widget.name.toLowerCase().includes(filterValue.toLowerCase())
        );
        this.dataSource$ = of(filteredWidgets);
      })
    );

    this.widgets$ = combineLatest([
      this.store.select(getWidgetTypesLoaded).pipe(filter((loaded) => loaded)),
      this.store.select(getWidgetTypesList).pipe(
        filter((data) => !!data.length),
        map((data) =>
          data.filter((item) => this.mapContentTypeIntoEmbedFlag(this.contentType, item))
        ),
        take(1),
        tap((widgetTypes) => {
          this.allowedWidgetTypes = [];

          const widgetTypesMap = widgetTypes.reduce((obj, item) => {
            return {
              ...obj,
              [item.id]: item,
            };
          }, {});

          this.allowedWidgetsIdsFromConfig.forEach((id) => {
            const widget = widgetTypesMap[id];
            if (widget) {
              this.allowedWidgetTypes.push(widget);
            }
          });

          // in case we don't have allowed widget ids then we will use the all system/third party widgets as allowed widget types
          if (!this.allowedWidgetTypes.length) {
            this.allowedWidgetTypes = widgetTypes;
          }

          if (!this.widgetId) {
            // load the list of widgets
            this.dataSource$ = of(this.allowedWidgetTypes);
            return;
          }

          this.loadDefaultValues = false;
          const widget = this.allowedWidgetTypes.find((item) => item.id === this.widgetId);
          // load active widget
          this.dataSource$ = of([widget]);
          this.filterField.setValue(widget);
          this.prepareConfigurationField(widget);
        })
      ),
    ]).pipe(map(([, widgets]) => widgets));
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearWidgetTypesAction());
  }

  mapContentTypeIntoEmbedFlag(type, item) {
    switch (type) {
      case 'ARTICLE':
        return item.articleEmbed;
      case 'LR_POST':
        return item.liveReportEmbed;
      case 'COLLECTION':
        return item.collectionEmbed;
      default:
        return true;
    }
  }

  prepareConfigurationField(widget) {
    if (!widget) {
      return;
    }
    widget = this.updateOldCustomData(widget);
    this.embeddableForm.get('title').setValue(widget.name);
    if (this.usage === 'COLLECTION') {
      this.embeddableForm.get('updatedBy').setValue(widget.updatedBy || widget.createdBy);
      this.embeddableForm.get('updatedAt').setValue(widget.updatedAt);
    }
    this.embeddableForm.removeControl('configuration');

    this.widgetFormConfig =
      widget.configuration && widget.configuration.length > 0 ? widget.configuration : null;
    const initialValues = this.loadDefaultValues ? null : this.dialogInputDataConfiguration || {};
    // TODO refactor - move responsibility to DynamicWidgetFormComponent
    this.initialValues = initialValues;
    const widgetFormGroup = this.fc.createFormGroup(this.widgetFormConfig, initialValues, {
      editMode: !!initialValues,
      contentLocaleId: this.activeLocale?.id || null
    });
    this.embeddableForm.addControl('configuration', widgetFormGroup);
  }

  updateOldCustomData(widget) {
    const { configuration } = widget;
    const newConfiguration = configuration.map((item) => {
      const { dataSource, fieldType } = item;
      if (dataSource.type === DataSourceOptions.CustomData && fieldType === 'autocomplete') {
        const newValue = dataSource.value.map((value) =>
          typeof value === 'string' ? { label: value, value: value } : value
        );
        return {
          ...item,
          dataSource: {
            ...dataSource,
            value: newValue,
          },
        };
      }

      if (dataSource.type === DataSourceOptions.NoData && fieldType === 'texteditor') {
        /*
          In case we have text editor added in "system widget/third party widget" and when invoke embed-widget-dialog.component
          then issue arises with froala text editor toolbar. This code is used to crete new property on fieldConfig
          so we can use this class to update the scrollableContainer property.
        */
        item['scrollableContainer'] = '.mat-dialog-container';
      }

      return item;
    });
    return { ...widget, configuration: newConfiguration };
  }

  changeWidget(widget) {
    this.embeddableForm.get('id').setValue(widget.id);
    this.activeWidget = widget;
    this.loadDefaultValues = true;
    this.prepareConfigurationField(widget);
  }

  getWidgetsData(): Observable<any> {
    return combineLatest([
      this.store.select(getWidgetTypesLoaded).pipe(filter((loaded) => loaded)),
      this.store.select(getWidgetTypesList).pipe(
        filter((widgetTypes) => !isEmpty(widgetTypes)),
        map((widgetTypes) =>
          widgetTypes.filter((item) => this.mapContentTypeIntoEmbedFlag(this.contentType, item))
        ),
        map((widgetTypes) => {
          let allowedWidgetTypes = [];
          const widgetTypesMap = widgetTypes.reduce((obj, item) => {
            return {
              ...obj,
              [item.id]: item,
            };
          }, {});

          this.allowedWidgetsIdsFromConfig.forEach((id) => {
            const widget = widgetTypesMap[id];
            if (widget) {
              allowedWidgetTypes.push(widget);
            }
          });

          // in case we don't have allowed widget ids then we will use the all system/third party widgets as allowed widget types
          if (!allowedWidgetTypes.length) {
            allowedWidgetTypes = widgetTypes;
          }
          return allowedWidgetTypes;
        }),
        mergeMap((allowedWidgetTypes) => {
          let isWidgetSelected = !!this.widgetId;
          let selectedWidget;
          if (isWidgetSelected) {
            selectedWidget = allowedWidgetTypes.find((item) => item.id === this.widgetId);
          }

          return iif(
            () => isWidgetSelected,
            of([selectedWidget]).pipe(
              tap(([widget]) => {
                this.loadDefaultValues = false;
                this.filterField.setValue(widget);
                this.prepareConfigurationField(widget);
              })
            ),
            of(allowedWidgetTypes)
          );
        })
      ),
    ]).pipe(map(([, widgets]) => widgets));
  }
}
