import { Injectable } from '@angular/core';



@Injectable()
export class BreadcrumbService {

  private routesFriendlyNames: Map<string, string> = new Map<string, string>();
  private routesFriendlyNamesRegex: Map<string, string> = new Map<string, string>();
  private routesWithCallback: Map<string, (string) => string> = new Map<string, (string) => string>();
  private routesWithCallbackRegex: Map<string, (string) => string> = new Map<string, (string) => string>();
  private hideRoutes: Array<string> = [];
  private hideRoutesRegex: Array<string> = [];
  private routeNames = {
    'dashboard': $localize `Dashboard`,
    'articles': $localize `Articles`,
    'taxonomies': $localize `Taxonomies`,
    'article-types': $localize `Article Types`,
    'templates': $localize `Templates`,
    'site-builder': $localize `Site Builder`,
    'system-widgets': $localize `System Widgets`,
    'third-party-widgets': $localize `Third Party Widgets`,
    'adverts': $localize `Adverts`,
    'advert-groups': $localize `Advert groups`,
    'pages': $localize `Pages`,
    'routes': $localize `Routes`,
    'page-routes': $localize `Routes`,
    'media': $localize `Media`,
    'images': $localize `Images`,
    'files': $localize `Files`,
    'galleries': $localize `Galleries`,
    'subscribers' : $localize `Event Subscribers`,
    'configuration': $localize `Configuration`,
    'glide-users': $localize `Glide Users`,
    'accounts': $localize `Accounts`,
    'roles': $localize `Roles`,
    'users': $localize `Users`,
    'create': $localize `Create`,
    'settings': $localize `Settings`,
    'upload': $localize `Upload`,
    'menus': $localize `Menus`,
    'html-snippets': $localize `HTML Snippets`,
    'my-profile': $localize `My profile`,
    'authors': $localize `Authors`,
    'redirects': $localize `Redirects`,
    'widgets': $localize `Widgets`,
    'content-panels': $localize `Content Panels`,
    'custom-field-groups': $localize `Custom Field Groups`,
    'collection-types': $localize `Collection Types`,
    'collections': $localize `Collections`,
    'content-tags': $localize `Content Tags`,
    'sso': $localize `SSO`,
    'taxonomy-configuration': $localize `Taxonomy Configuration`,
    'verify': $localize `Verify`,
    'access-bundles': $localize `Access Bundles`,
    'verify-config': $localize `Configure`,
    'media-config': $localize `Configure`,
    'live-reporting': $localize `Live Reporting`,
    'transmit': $localize `Transmit`,
    'links-report': $localize `Links Report`,
    'content-locales' : $localize `Internationalisation`,
    'notifications': $localize `Notifications`,
    'system-notifications': $localize `System`,
    'generate': $localize `Generate`,
  };

  /**
   * Specify a friendly name for the corresponding route.
   *
   * @param route
   * @param name
   */
  addFriendlyNameForRoute(route: string, name: string): void {
    this.routesFriendlyNames.set(route, name);
  }

  /**
   * Specify a friendly name for the corresponding route matching a regular expression.
   *
   * @param routeRegex
   * @param name
   */
  addFriendlyNameForRouteRegex(routeRegex: string, name: string): void {
    this.routesFriendlyNamesRegex.set(routeRegex, name);
  }

  /**
   * Specify a callback for the corresponding route.
   * When a mathing url is navigatedd to, the callback function is invoked to get the name to be displayed in the breadcrumb.
   */
  addCallbackForRoute(route: string, callback: (id: string) => string): void {
    this.routesWithCallback.set(route, callback);
  }

  /**
   * Specify a callback for the corresponding route matching a regular expression.
   * When a mathing url is navigatedd to, the callback function is invoked to get the name to be displayed in the breadcrumb.
   */
  addCallbackForRouteRegex(routeRegex: string, callback: (id: string) => string): void {
    this.routesWithCallbackRegex.set(routeRegex, callback);
  }

  /**
   * Show the friendly name for a given route (url). If no match is found the url (without the leading '/') is shown.
   *
   * @param route
   * @returns {*}
   */
  getFriendlyNameForRoute(route: string): string {
    route = route.match(/[^?]*/)[0];
    let name;
    const routeEnd = route.substr(route.lastIndexOf('/') + 1, route.length);

    this.routesFriendlyNames.forEach((value, key, map) => {
      if (key === route) {
        name = value;
      }
    });

    this.routesFriendlyNamesRegex.forEach((value, key, map) => {
      if (new RegExp(key).exec(route)) {
        name = value;
      }
    });

    this.routesWithCallback.forEach((value, key, map) => {
      if (key === route) {
        name = value(routeEnd);
      }
    });

    this.routesWithCallbackRegex.forEach((value, key, map) => {
      if (new RegExp(key).exec(route)) {
        name = value(routeEnd);
      }
    });

    const routeName = this.routeNames[routeEnd] || $localize`Update`;
    return name ? name : routeName;
  }

  /**
   * Specify a route (url) that should not be shown in the breadcrumb.
   */
  hideRoute(route: string): void {
    if (!(this.hideRoutes.indexOf(route) != -1)) {
      this.hideRoutes.push(route);
    }
  }

  /**
   * Specify a route (url) regular expression that should not be shown in the breadcrumb.
   */
  hideRouteRegex(routeRegex: string): void {
    if (!(this.hideRoutesRegex.indexOf(routeRegex) != -1)) {
      this.hideRoutesRegex.push(routeRegex);
    }
  }

  /**
   * Returns true if a route should be hidden.
   */
  isRouteHidden(route: string): boolean {
    let hide = (this.hideRoutes.indexOf(route) != -1);

    this.hideRoutesRegex.forEach((value) => {
      if (new RegExp(value).exec(route)) {
        hide = true;
      }
    });

    return hide;
  }

}
